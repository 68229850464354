import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

interface TallOuterContainerProps {
  dynamicHeight: number;
}
type TallOuterContainerStyledProps = React.HTMLProps<HTMLDivElement> &
  TallOuterContainerProps;

const TallOuterContainer = styled.div.attrs<TallOuterContainerStyledProps>(
  ({ dynamicHeight }) => ({
    style: { height: `${dynamicHeight}px` },
  })
)`
  position: relative;
  width: 100%;
`;

const StickyInnerContainer = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
`;

const calcDynamicHeight = (objectWidth: number) => {
  const vw = window.innerWidth;
  const vh = window.innerHeight;
  return objectWidth - vw + vh + 150;
};

const handleDynamicHeight = (
  ref: React.RefObject<HTMLDivElement>,
  setDynamicHeight: React.Dispatch<React.SetStateAction<number>>
) => {
  if (ref.current) {
    const objectWidth = ref.current.scrollWidth;
    const dynamicHeight = calcDynamicHeight(objectWidth);
    setDynamicHeight(dynamicHeight);
  }
};

const applyScrollListener = (
  ref: React.RefObject<HTMLDivElement>,
  setTranslateX: React.Dispatch<React.SetStateAction<number>>
) => {
  window.addEventListener("scroll", () => {
    if (ref.current) {
      const offsetTop = -ref.current.offsetTop;
      setTranslateX(offsetTop);
    }
  });
};

export default ({ children }: { children: React.ReactNode }) => {
  const [dynamicHeight, setDynamicHeight] = useState(0);
  const [translateX, setTranslateX] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const objectRef = useRef<HTMLDivElement>(null);

  const resizeHandler = () => {
    handleDynamicHeight(objectRef, setDynamicHeight);
  };

  useEffect(() => {
    handleDynamicHeight(objectRef, setDynamicHeight);
    window.addEventListener("resize", resizeHandler);
    applyScrollListener(containerRef, setTranslateX);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return (
    <TallOuterContainer dynamicHeight={dynamicHeight}>
      <StickyInnerContainer ref={containerRef}>
        <motion.div
          initial={{ x: 0 }}
          animate={{ x: translateX }}
          transition={{ duration: 0 }}
          ref={objectRef}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            height: "100%",
            willChange: "transform",
            gap: "5vw",
            paddingLeft: "5vw",
            paddingRight: "5vw",
            // marginTop: 200,
          }}
        >
          {children}
        </motion.div>
      </StickyInnerContainer>
    </TallOuterContainer>
  );
};
