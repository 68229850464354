import React from "react";
import "./customer.scss";
import HowItWorks from "../../Components/HowItWorks/HowItWorks";
import FormCustomer from "../../Components/FormCustomer/FormCustomer";
import FaqCustomer from "../../Components/Faq/FaqCustomer";
import { useNavigate } from "react-router-dom";
import MuxPlayer from "@mux/mux-player-react";
import Hero from "./Hero";
import WhyZap from "./Why";
import ParticipantCompanies from "./participants";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";

const down = (
  <svg
    width="19"
    height="9"
    viewBox="0 0 19 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M17.34 0.951248L10.82 7.47125C10.05 8.24125 8.79 8.24125 8.02 7.47125L1.5 0.951248"
      stroke="white"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const up = (
  <svg
    width="18"
    height="9"
    viewBox="0 0 18 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M16.84 8.04875L10.32 1.52875C9.55 0.758751 8.29 0.758751 7.52 1.52875L1 8.04875"
      stroke="white"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

function Customer({ customerFaq }: { customerFaq: any }) {
  // useNavigate
  const navigate = useNavigate();

  // useState
  const [showDropDown, setShowDropDown] = React.useState(false);

  // useRef
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const isSpeedIncreased = React.useRef(false);

  try {
    if (!isSpeedIncreased.current && videoRef.current !== null) {
      videoRef.current.playbackRate += 7.5;
      isSpeedIncreased.current = true;
    }
  } catch (error) {
    isSpeedIncreased.current = false;
  }

  return (
    <Flex
      flexDir={"column"}
      w={"100vw"}
      bg={"black"}
      className="customer-container"
    >
      <div className="mobile-header">
        <button
          onClick={() => window.scrollTo(0, 0)}
          style={{
            backgroundColor: "transparent",
            borderWidth: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p>Pay with</p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h2 className="zap">Zap</h2>
            <img src="/logo-alt.png" alt="" />
          </div>
        </button>

        <button
          style={{
            borderBottomLeftRadius: showDropDown ? 0 : 10,
            borderBottomRightRadius: showDropDown ? 0 : 10,
            marginBottom: 10,
          }}
          className="customer-drop-down"
          onClick={() => setShowDropDown((prev) => !prev)}
        >
          <p>Customer</p>
          {showDropDown ? up : down}
        </button>

        {showDropDown && (
          <button
            className="customer-drop-down"
            onClick={() => navigate("/forSaaS")}
            style={{
              position: "absolute",
              top: 72,
              right: 22,
              width: 130,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              background: "rgba(255, 255, 255, 1)",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ color: "#000" }}>for SaaS</p>
          </button>
        )}
      </div>

      <Hero />
      <WhyZap />
      <HowItWorks />
      <ParticipantCompanies />

      <Flex
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        minH={{ md: "100vh" }}
        pos={"relative"}
        id="zap-in-action"
        overflow={"hidden"}
        mt={{ base: 10, md: 0 }}
      >
        <motion.div
          whileInView={{ transform: ["scale(0.1)", "scale(1)"] }}
          transition={{ duration: 0.5, type: "spring" }}
          style={{
            position: "absolute",
            alignSelf: "center",
            overflow: "hidden",
          }}
        >
          <Box
            as="video"
            src="videos/gradient.mp4"
            muted
            ref={videoRef}
            resize={"block"}
            autoPlay
            mt={{ md: -24, base: -4 }}
            playsInline
            alignSelf={"center"}
            transform={{
              md: `scaleX(1.9) scaleY(1.15)`,
              base: `scaleX(1.4) scaleY(1.1)`,
            }}
            loop
          />
        </motion.div>

        <Flex
          flexDir={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          h={"full"}
          w={"full"}
          py={{ base: 12, md: 24 }}
          pos={"relative"}
          bg={"rgba( 255, 255, 255, 0 )"}
          backdropFilter={`blur(40.5px)`}
          __css={{ WebkitBackdropFilter: `blur(40.5px)` }}
        >
          <Heading
            fontSize={{ base: `calc(90vw * 0.055)`, md: "4rem" }}
            lineHeight={{ base: `calc(90vw * 0.055)`, md: "4rem" }}
            fontFamily={"Lexend"}
            textAlign={"center"}
            fontWeight={"900"}
            color={"#fff"}
          >
            See how Zap works in action
          </Heading>

          <Text
            color={"#fff"}
            opacity={0.95}
            textAlign={"center"}
            fontFamily={"Lexend"}
            mt={2}
            fontSize={{ base: 14, md: 24 }}
          >
            It only takes 2 mins to save 1000s of dollars, all while
            conveniently paying on a monthly basis.
          </Text>

          <MuxPlayer
            theme="minimal"
            playbackId="COkCqJfiPOx01jmns201aTfzfm9gdVchagRVDKJij98JE"
            poster="/video_poster.png"
            style={{
              width: "80vw",
              maxWidth: "1440px",
              marginLeft: "10vw",
              marginRight: "10vw",
              alignSelf: "center",
              marginTop: 40,
            }}
          />
        </Flex>
      </Flex>

      <FormCustomer />
      {FaqCustomer && <FaqCustomer customerFaq={customerFaq} />}
    </Flex>
  );
}

export default Customer;
