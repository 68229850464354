import React from "react";
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";

function SuperchargeRevenue() {
  // useState
  const [showAfter, setCanShowAfter] = React.useState(false);

  // useEffect
  React.useEffect(() => {
    const interval = setInterval(
      () => setCanShowAfter((prev) => !prev),
      1000 * 2
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Flex
      minH={{ md: "100vh" }}
      flexDir={"column"}
      alignItems={"center"}
      pt={{ base: 8, md: 12 }}
      pb={{ base: 8, md: 32 }}
      as={"section"}
      gap={{ base: 6, md: 12 }}
    >
      <motion.div
        initial={{ y: 200, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
      >
        <Heading
          textAlign={"center"}
          className="gradient-text"
          fontFamily={"Lexend"}
          lineHeight={{ md: "119%" }}
          size={{ base: "lg", md: "4xl" }}
        >
          Supercharge your revenue
          <br />
          generating machine
        </Heading>
      </motion.div>

      <Box
        display={{ base: "none", md: "block" }}
        rounded={"xl"}
        overflow={"hidden"}
        pos={"relative"}
        w={{ md: "642px" }}
        h={"360px"}
      >
        <motion.img
          src="/svgs/superchargeGraph/before.svg"
          alt="/svgs/superchargeGraph/before.svg"
          animate={{ opacity: showAfter ? 0 : 1 }}
          style={{ position: "absolute", bottom: "42px", left: "40px" }}
        />

        <motion.img
          src="/svgs/superchargeGraph/after.svg"
          alt="/svgs/superchargeGraph/after.svg"
          animate={{ opacity: showAfter ? 1 : 0 }}
          style={{ position: "absolute", left: "40px", bottom: "42px" }}
        />

        <motion.div animate={{ backgroundColor: "rgba(112, 109, 115, 0.48)" }}>
          <Flex
            justifyContent={"space-between"}
            flexDir={"column"}
            rounded={"xl"}
            p={6}
            h={"360px"}
            pos={"relative"}
            w={"642px"}
          >
            <Heading
              as={motion.h1}
              animate={{ opacity: showAfter ? 1 : 0 }}
              size={{ md: "md" }}
              mb={{ md: 1 }}
              fontFamily={"Lexend"}
              color={"#fff"}
              alignSelf={"center"}
            >
              Amplify your revenue overnight
            </Heading>

            <Flex
              mb={"46px"}
              ml={"12px"}
              alignSelf={"flex-start"}
              alignItems={"center"}
            >
              <Box h={"4"} w="4" rounded={"full"} bg={"#34B53A"} />
              <Text fontFamily={"Poppins"} ml={2} color={"#fff"}>
                {" "}
                Revenue
              </Text>
            </Flex>

            <img
              src="/svgs/superchargeGraph/number.svg"
              alt="/svgs/superchargeGraph/number.svg"
              style={{ position: "absolute", left: "40px", bottom: "20px" }}
            />
          </Flex>
        </motion.div>
      </Box>

      <Box
        display={{ base: "block", md: "none" }}
        rounded={"xl"}
        overflow={"hidden"}
        pos={"relative"}
        w={"80vw"}
        h={"55vw"}
      >
        <Image
          src="/svgs/superchargeGraph/after.svg"
          pos={"absolute"}
          w={"70vw"}
          left={"20px"}
          bottom={"40px"}
        />

        <motion.div
          animate={{
            backgroundColor: "rgba(252, 252, 252, 0.26)",
          }}
        >
          <Box rounded={"xl"} p={4} h={"60vw"} pos={"relative"} w={"full"}>
            <Flex
              pos={"absolute"}
              top={"35vw"}
              left={"6"}
              alignItems={"center"}
            >
              <Box h="2" w="2" rounded={"full"} bg={"#34B53A"} />
              <Text fontFamily={"Poppins"} fontSize={"sm"} color="#fff" ml={2}>
                {" "}
                Revenue
              </Text>
            </Flex>

            <Heading
              size={{ md: "md", base: "xs" }}
              mb={{ md: 1 }}
              fontFamily={"Lexend"}
              color={"#fff"}
              textAlign={"center"}
              alignSelf={"center"}
            >
              Amplify your revenue overnight
            </Heading>
          </Box>
        </motion.div>

        <motion.img
          src="/svgs/superchargeGraph/number.svg"
          style={{
            position: "absolute",
            left: "20px",
            bottom: "20px",
            width: "70vw",
          }}
        />
      </Box>

      <Box
        fontFamily={"Lexend"}
        fontWeight={"700"}
        color={"#fff"}
        fontSize={"20px"}
        bg={"rgba(35, 129, 72, 0.49)"}
        borderColor={"#238148"}
        borderWidth={1}
        rounded={"full"}
        px={4}
        py={1}
      >
        Revenue charts will explode
      </Box>
    </Flex>
  );
}

export default React.memo(SuperchargeRevenue);
