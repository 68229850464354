import { Box, Heading } from "@chakra-ui/react";
import React from "react";

function RefundCancellation() {
  return (
    <Box py={"20vh"} px={"10vw"}>
      <div className="WordSection1">
        <Heading mb={4}>Refund & Cancellation</Heading>

        <p className="MsoNormal">
          <span>&nbsp;</span>
        </p>

        <p className="MsoNormal">
          Once a software subscription is purchased, it shall be considered
          final and binding. Amendments or modifications to the subscription are
          generally not possible, as Zap advances the purchase value to the
          partnered SaaS company. However, customers have the option to request
          the cancellation of an active subscription.
        </p>
        <br />

        <p className="MsoNormal">
          The decision to approve a cancellation request is at the discretion of
          Zap, in consultation with the partnered SaaS company. If a
          cancellation is granted, customers may be subject to a pre-closure
          fee, and they will be required to repay the prorated amount
          corresponding to any discount enjoyed during the initial purchase.
        </p>
        <br />

        <p className="MsoNormal">
          To request a refund or cancellation, customers can contact Zap's
          support team by writing an email to support@paywithzap.com. Zap will
          review each request on a case-by-case basis. The processing of the
          request will typically be completed within 7-14 working days, and a
          final decision will be made after consultation with the SaaS partner.
        </p>
        <br />

        <p className="MsoNormal">
          If a cancellation request is approved, the customer's outstanding
          balance will be reduced to zero after payment of relevant charges,
          including cancellation and processing fees, late payment charges, and
          any other applicable charges levied by both the SaaS partner and Zap.
        </p>
        <br />

        <p className="MsoNormal">
          It is important to note that the ultimate decision to cancel a
          subscription lies with the SaaS partner. However, Zap will make every
          effort to advocate for the customer's request and work towards a
          resolution with the SaaS partner. Please note that this refund and
          cancellation policy is subject to the terms and conditions outlined in
          the original agreement between the customer, Zap, and the partnered
          SaaS company.
        </p>
        <br />

        <p className="MsoNormal">
          If you have any further questions or concerns regarding the refund and
          cancellation process, please reach out to our support team at
          support@paywithzap.com.
        </p>
      </div>
    </Box>
  );
}

export default RefundCancellation;
