import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import { motion } from "framer-motion";

function WinWin() {
  return (
    <Flex
      as={"section"}
      alignItems={"center"}
      justifyContent={"center"}
      w="100vw"
      flexDir={"column"}
      py={{ md: 16, base: 16 }}
      gap={{ md: 10, base: 6 }}
      pos={"sticky"}
      minH={{ base: "fit-content", md: "100vh" }}
      overflowY={"hidden"}
    >
      <Box
        display={{ base: "none", md: "flex" }}
        pos={"absolute"}
        top={0}
        left={0}
      >
        <motion.div
          initial={{ y: 300, opacity: 0, scale: 0.6 }}
          whileInView={{ y: 0, opacity: 1, scale: 1 }}
          transition={{ delay: 0.1 }}
        >
          <Image w={`calc(30vw)`} src="/svgs/WinWIn/LeftIcon.svg" />
        </motion.div>
      </Box>

      <Box
        display={{ base: "none", md: "flex" }}
        pos={"absolute"}
        top={"30vh"}
        right={"5vw"}
      >
        <motion.div
          initial={{ y: 200, opacity: 0, scale: 0.6 }}
          whileInView={{ y: 0, opacity: 1, scale: 1 }}
          transition={{ delay: 0.1 }}
        >
          <Image w={`calc(15vw)`} src="/svgs/WinWIn/RightIcon.svg" />
        </motion.div>
      </Box>

      <motion.div
        initial={{ y: 200, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ delay: 0, type: "spring" }}
      >
        <Heading
          zIndex={1000}
          fontFamily={"GothamBold"}
          size={{ base: "lg", md: "3xl" }}
          textAlign={"center"}
          letterSpacing={"-1px"}
          className="gradient-text"
        >
          Don’t fight it! If customers
          <br /> want to pay monthly let’em
        </Heading>
      </motion.div>

      <motion.div
        initial={{ y: 150, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.15, type: "spring" }}
      >
        <Text
          textAlign={"center"}
          color="#fff"
          fontSize={{ base: "lg", md: "2xl" }}
          fontFamily={"Lexend"}
          w={{ base: "90vw", md: "80vw" }}
        >
          We know you want your customers to pay annually, but none of the them
          like to pay an annual upfront. With Zap as a payment method, customers
          would love to commit to annual plans, along with the flexibility of
          monthly payments. You’ll receive the full payment upfront, while Zap
          finances the subscription on customer’s behalf. It’s a WIN-WIN for all
          !!
        </Text>
      </motion.div>
    </Flex>
  );
}

export default WinWin;
