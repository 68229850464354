import { Box, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import { motion } from "framer-motion";

function UpliftMetrics() {
  return (
    <Flex
      minH={{ md: "100vh" }}
      flexDir={"column"}
      // bg={"#1A0B2E"}
      bg={"#18092d"}
      alignItems={"center"}
      pt={{ base: 8, md: 12 }}
      pb={{ base: 8, md: 32 }}
      as={"section"}
      gap={{ base: 6, md: 12 }}
    >
      <motion.div
        initial={{ y: 200, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
      >
        <Heading
          className="gradient-text"
          fontFamily={"Lexend"}
          size={{ base: "lg", md: "4xl" }}
        >
          Uplift metrics that matter
        </Heading>
      </motion.div>

      <Box
        as="video"
        controls={false}
        autoPlay
        loop
        muted
        w={"70vw"}
        playsInline
        src="videos/uplift-metrics.mp4"
      />
    </Flex>
  );
}

export default React.memo(UpliftMetrics);
