import React from "react";
import { Box, Divider, Flex, Heading, Image, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";

function Hero() {
  // useState
  const [background, setBackground] = React.useState(
    `linear-gradient(90deg, #ffc837 0%, #ff8008 100%)`
  );
  const [shadow, setShadow] = React.useState("#ff8008");

  const [text1, setText1] = React.useState("");
  const [text2, setText2] = React.useState("");

  const [showCursor, setShowCursor] = React.useState(true);

  // useEffect
  React.useEffect(() => {
    const interval = setInterval(() => {
      setBackground((prev) => {
        if (prev === `linear-gradient(90deg, #ff8008 0%, #ffc837 100%)`)
          return `linear-gradient(90deg, #ffc837 0%, #ff8008 100%)`;
        // return `linear-gradient(90deg, #ff8008 0%, #ffc837 100%)`;
        return `linear-gradient(90deg, #ff8008 0%, #ffc837 100%)`;
      });

      setShadow((prev) => {
        if (prev === "#ff8008") {
          return "#ffc837";
        }

        return "#ff8008";
      });
    }, 2500);

    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const textArray = ["Give customers", "what they want"];
    let currentIndex = 0;
    let count = 0;

    const interval = setInterval(() => {
      if (currentIndex === 0) {
        setText1((prev) => {
          if (prev === textArray[0]) {
            currentIndex = 1;
            return prev;
          }

          return textArray[currentIndex].slice(0, prev.length + 1);
        });
      } else {
        if (count > 2)
          setText2((prev) => textArray[currentIndex].slice(0, prev.length + 1));
        else count++;
      }
    }, 75);

    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const cursorInterval = setInterval(() => {
      setShowCursor((prevShowCursor) => !prevShowCursor);
    }, 500);

    return () => clearInterval(cursorInterval);
  }, []);

  return (
    <Flex
      as={"section"}
      flexDir={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      pt={{ md: "10vh", base: "80px" }}
      pb={{ md: "10vh", base: "80px" }}
      minH={{ md: "100vh" }}
      bg={`linear-gradient(180deg, #000 0%, #1A0B2E 100%)`}
      w={"full"}
    >
      <motion.div initial={{ y: 100 }} whileInView={{ y: 0 }}>
        <motion.div
          whileInView={{
            background,
            boxShadow: `0px 0px 12px 8px ${shadow}80`,
          }}
          initial={{
            background: `linear-gradient(90deg, #ff8008 0%, #ffc837 100%)`,
            boxShadow: `0px 0px 12px 8px #ff800880`,
          }}
          transition={{ duration: 2.5 }}
          style={{
            backdropFilter: "blur(10px)",
            borderRadius: 24,
            WebkitBackdropFilter: "blur(10px)",
            padding: 1,
          }}
        >
          <Flex px={"36px"} py={"12px"} bg={"#000"} borderRadius={"24px"}>
            <Text
              className="gradient-text"
              fontFamily={"Lexend"}
              display={{ base: "none", md: "block" }}
              fontSize={{ md: "36px", base: "20px" }}
            >
              Buy Now Pay Later for SaaS is here
            </Text>
            <Text
              className="gradient-text"
              fontFamily={"Lexend"}
              textAlign={"center"}
              display={{ base: "block", md: "none" }}
              fontSize={{ md: "36px", base: "20px" }}
            >
              Buy Now Pay Later
              <br />
              for SaaS is here
            </Text>
          </Flex>
        </motion.div>
      </motion.div>

      <motion.div
        initial={{ y: 100, opacity: 0 }}
        transition={{ delay: 0.15, type: "spring" }}
        whileInView={{ y: 0, opacity: 1 }}
      >
        <Box pos={"relative"} mt={{ md: 12, base: 6 }}>
          <Heading
            color={"#fff"}
            zIndex={1}
            fontSize={{ base: `calc(80vw * 0.125)`, md: "100px" }}
            lineHeight={"102%"}
            fontFamily={"GothamUltra"}
            fontWeight={"500"}
            letterSpacing={"-.5px"}
          >
            Give customers
          </Heading>
          <Heading
            fontSize={{ base: `calc(80vw * 0.125)`, md: "100px" }}
            lineHeight={"102%"}
            className="gradient-text"
            zIndex={100}
            fontFamily={"GothamUltra"}
            fontWeight={"500"}
            letterSpacing={"-.5px"}
            pos={"absolute"}
            top={0}
          >
            {text1}
            {text1 !== "Give customers" && (
              <span>
                <Box
                  bg={"linear-gradient(90deg, #ff8008 0%, #ffc837 100%)"}
                  display={"inline-block"}
                  h={{ base: `calc(80vw * 0.125)`, md: "100px" }}
                  w={{ md: "18px", base: "8px" }}
                  mb={{ md: -5, base: -2 }}
                  top={10}
                />
              </span>
            )}
          </Heading>
        </Box>
      </motion.div>

      <motion.div
        initial={{ y: 100, opacity: 0 }}
        transition={{ delay: 0.25, type: "spring" }}
        whileInView={{ y: 0, opacity: 1 }}
      >
        <Box pos={"relative"} mb={{ md: 12, base: 6 }}>
          <Heading
            fontSize={{ base: `calc(80vw * 0.125)`, md: "100px" }}
            lineHeight={"102%"}
            className="gradient-text"
            zIndex={100}
            fontFamily={"GothamUltra"}
            fontWeight={"500"}
            letterSpacing={"-.5px"}
            pos={"absolute"}
            top={0}
            display={"flex"}
          >
            {text2}
            {text1 === "Give customers" &&
              text2 !== "what they want" &&
              text2.length > 0 && (
                <span>
                  <Box
                    bg={"linear-gradient(90deg, #ff8008 0%, #ffc837 100%)"}
                    display={"inline-block"}
                    h={{ base: `calc(80vw * 0.125)`, md: "100px" }}
                    w={{ md: "18px", base: "8px" }}
                    mb={{ md: -5, base: -2 }}
                    top={10}
                  />
                </span>
              )}

            <span>
              {text2 === "what they want" && showCursor && (
                <Box
                  animation={"blink 1s infinite alternate"}
                  bg={"linear-gradient(90deg, #ff8008 0%, #ffc837 100%)"}
                  pos={"absolute"}
                  h={{ base: `calc(80vw * 0.125)`, md: "100px" }}
                  w={{ md: "18px", base: "8px" }}
                  mb={{ md: -5, base: -2 }}
                />
              )}
            </span>
          </Heading>
          <Heading
            fontSize={{ base: `calc(80vw * 0.125)`, md: "100px" }}
            lineHeight={"102%"}
            color={"#fff"}
            zIndex={1}
            fontFamily={"GothamUltra"}
            fontWeight={"500"}
            letterSpacing={"-.5px"}
          >
            what they want
          </Heading>
        </Box>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, width: "10vw" }}
        whileInView={{ opacity: 1, width: "60vw" }}
        transition={{ delay: 0.25, duration: 0.25, type: "spring" }}
      >
        <Divider bg={"#fff"} w={"full"} />
      </motion.div>

      <motion.div
        initial={{ y: 100, opacity: 0 }}
        transition={{ delay: 0.45, type: "spring" }}
        whileInView={{ y: 0, opacity: 1 }}
      >
        <Text
          display={"flex"}
          fontFamily={"Space Mono"}
          color="#fff"
          fontSize={{ md: "2.5rem", base: "1rem" }}
          fontWeight={"700"}
          letterSpacing={"-0.5px"}
          my={{ md: 6, base: 2 }}
          ml={{ md: -10, base: -6 }}
          pos={"relative"}
        >
          They will love you for it ️
          <Box
            pos={"absolute"}
            right={{ md: -10, base: -6 }}
            top={{ md: 2, base: 1 }}
            as="span"
            mb={"-12px"}
          >
            <Image
              h={{ md: "2.75rem", base: "1.25rem" }}
              w={{ md: "2.75rem", base: "1.25rem" }}
              src="svgs/heart.svg"
            />
          </Box>
        </Text>
      </motion.div>
    </Flex>
  );
}

export default React.memo(Hero);
