import React from "react";
import "./styles.scss";
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import HorizontalScroll from "./horizontalScroll";
import { motion } from "framer-motion";
import MotionWrap from "../../../utils/MotionWrap";

function WhyZap() {
  const arr = [
    {
      src: "svgs/boost-runway.svg",
      alt: "boost-runway",
      title: "Boost Runway",
      desc: "SaaS spends eat up as much a quarter of your annual capital spends. Pay monthly and get some space to breathe",
    },
    {
      src: "svgs/free-up-capital.svg",
      alt: "free-up-capital",
      title: "Free up Capital",
      desc: "Don't block cash upfront, there are better places to deploy your capital",
    },
    {
      src: "svgs/one-click-to-subscribe.svg",
      alt: "one-click-to-subscribe",
      title: "One click to Subscribe",
      desc: "Zap offers a one click approval, thanks to the strong SaaS partnerships that we have. Subscribe and Payback on the fly with Zap.",
    },
  ];

  return (
    <>
      <Flex
        pos={"relative"}
        bg={"black"}
        display={{ md: "none" }}
        w={"full"}
        pointerEvents={"none"}
        minH={"100vh"}
      >
        <Flex
          as={motion.div}
          whileInView={{ opacity: [0, 1] }}
          // pos={"absolute"}
          // mb={"200px"}
          w={"full"}
          top={10}
          alignItems={"center"}
          flexDir={"column"}
        >
          <Heading
            fontSize="2.5rem"
            lineHeight="2.5rem"
            fontFamily={"Lexend"}
            fontWeight={"900"}
            textAlign={"center"}
            color={"#fff"}
          >
            Why{" "}
            <Text as={"span"} className="gradient-text">
              Zap?
            </Text>
          </Heading>

          <Text
            textAlign={"center"}
            mt={{ base: 2 }}
            fontFamily={"Lexend"}
            fontSize={{ base: 14, md: 24 }}
            className="gradient-text-secondary"
          >
            Buy SaaS with Zap and pay for an annual plan, MONTHLY
          </Text>
        </Flex>

        <HorizontalScroll>
          {arr.map((item) => (
            <Box
              bg={`linear-gradient(134deg, rgba(87, 167, 110, 0.33) 0%, rgba(40, 108, 64, 0.26) 66.14%, rgba(16, 79, 41, 0.22) 100%)`}
              rounded={"2xl"}
              borderWidth={1}
              h={"420px"}
              alignSelf={"center"}
              borderColor={"white"}
              borderStyle={"solid"}
            >
              <Flex
                width={"90vw"}
                h={"420px"}
                rounded={"2xl"}
                bg={`linear-gradient(117deg, rgba(87, 167, 110, 0.33) 0%, rgba(40, 108, 64, 0.26) 30%, rgba(16, 79, 41, 0.22) 43%)`}
                alignItems={"center"}
                flexDir={"column"}
                py={8}
                px={6}
              >
                <Image
                  h={{ base: "40vw", md: "12.5vw" }}
                  w={{ base: "40vw", md: "12.5vw" }}
                  src={item.src}
                  alt={item.alt}
                />

                <Heading
                  size={"lg"}
                  mt={4}
                  fontFamily={"Poppins"}
                  color={"#fff"}
                >
                  {item.title}
                </Heading>
                <Text
                  fontSize={"lg"}
                  color={"#fff"}
                  opacity={0.7}
                  fontFamily={"Lexend"}
                  textAlign={"center"}
                  mt={2}
                >
                  {item.desc}
                </Text>
              </Flex>
            </Box>
          ))}
        </HorizontalScroll>
      </Flex>

      <Flex
        flexDir={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        as="section"
        bg={"black"}
        id="why-zap"
        display={{ md: "flex", base: "none" }}
        px={6}
        pb={40}
        minH={"100vh"}
      >
        <motion.div
          whileInView={{ opacity: [0, 1] }}
          transition={{ duration: 0.5 }}
        >
          <Heading
            fontSize={{ base: "2.5rem", md: "112px" }}
            lineHeight={{ base: "2.5rem", md: "102%" }}
            fontFamily={"Lexend"}
            textAlign={"center"}
            color={"#fff"}
            fontWeight={"900"}
          >
            Why{" "}
            <Text as={"span"} className="gradient-text">
              Zap?
            </Text>
          </Heading>
        </motion.div>

        <motion.div
          whileInView={{ opacity: [0, 1] }}
          transition={{ duration: 0.5 }}
        >
          <Heading
            fontFamily={"Lexend"}
            size={{ base: "sm", md: "lg" }}
            color={"#fff"}
            fontWeight={"400"}
            className="gradient-text-secondary"
            mt="6"
          >
            Buy SaaS subscriptions with Zap and pay for an annual plan,{" "}
            <Text as={"span"} fontWeight={"600"}>
              MONTHLY
            </Text>
          </Heading>
          {/* <Text
            textAlign={"center"}
            mt={{ md: 4, base: 1 }}
            fontFamily={"Lexend"}
            fontSize={{ base: 14, md: 26 }}
            className="gradient-text-secondary"
          >
            Buy SaaS subscriptions with Zap and pay for an annual plan,{" "}
            <Text as={"span"} fontWeight={"600"}>
              MONTHLY
            </Text>
          </Text> */}
        </motion.div>

        <Flex
          alignItems={"center"}
          alignSelf={{ base: "flex-start", md: "center" }}
          mt={{ md: 16, base: 8 }}
          w={"full"}
          gap={16}
          justifyContent="center"
        >
          {arr.map((item, index) => (
            <motion.div
              transition={{
                duration: index === 0 ? 0.5 : index === 1 ? 1 : 1.5,
                delay: 0.5,
                type: "spring",
              }}
              viewport={{ once: true }}
              initial={{ y: 300, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              style={{ width: "25vw", height: "420px" }}
            >
              <Flex
                rounded={"2xl"}
                w="25vw"
                h="420px"
                alignItems={"center"}
                flexDir={"column"}
                py={8}
                px={4}
              >
                <Image
                  h={{ base: "40vw", md: "12.5vw" }}
                  w={{ base: "40vw", md: "12.5vw" }}
                  src={item.src}
                  alt={item.alt}
                />

                <Heading
                  fontSize={`calc(25vw * 0.075)`}
                  mt={4}
                  fontFamily={"Poppins"}
                  color={"#fff"}
                >
                  {item.title}
                </Heading>
                <Text
                  fontSize={"xl"}
                  color={"#fff"}
                  opacity={0.7}
                  fontFamily={"Lexend"}
                  textAlign={"center"}
                  mt={2}
                >
                  {item.desc}
                </Text>
              </Flex>
            </motion.div>
          ))}
        </Flex>
      </Flex>
    </>
  );
}

export default MotionWrap(React.memo(WhyZap), "app__why");
