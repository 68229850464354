import { Box, Heading, Text } from "@chakra-ui/react";
import React from "react";

function Terms() {
  return (
    <Box py={"20vh"} px={"10vw"}>
      <Box className="WordSection1">
        <Heading mb={4}>Terms and Conditions</Heading>

        <p className="MsoNormal">
          <b>
            <u>1. INTRODUCTION</u>
          </b>
        </p>

        <br />
        <p className="MsoNormal">
          These terms and conditions outline the rules and regulations for the
          use of Zap's Website, located at 521F Diamond Heights Blvd, San
          Francisco, CA, 94131, United States.
        </p>

        <br />
        <p className="MsoNormal">
          By accessing this website we assume you accept these terms and
          conditions. Do not continue to use www.paywithzap.com if you do not
          agree to take all of the terms and conditions stated on this page.
        </p>

        <br />
        <p className="MsoNormal">
          The following terminology applies to these Terms and Conditions,
          Privacy Statement and Disclaimer Notice and all Agreements:
          "Customer", "You" and "Your" refers to you, the person log on this
          website and compliant to the Company's terms and conditions.
        </p>

        <br />
        <p className="MsoNormal">
          "The Company", "Ourselves", "We", "Our" and "Us", refers to our
          Company. "Party", "Parties", or "Us", refers to both the Client and
          ourselves.
        </p>

        <br />
        <p className="MsoNormal">
          Any use of the above terminology or other words in the singular,
          plural, capitalization and/or he/she or they, are taken as
          interchangeable and therefore as referring to same.
        </p>

        <br />
        <p className="MsoNormal">
          These Terms apply to all visitors, users and others who wish to access
          or use Service.
        </p>

        <br />
        <p className="MsoNormal">
          The Services provided by{" "}
          <b>
            <u>Zap</u>
          </b>{" "}
          through the Website are available and are appropriate only for use in{" "}
          <b>
            <u>Indian Subcontinent</u>
          </b>
          .
        </p>

        <br />
        <p className="MsoNormal">
          <b>
            <u>2. USE OF SERVICES</u>
          </b>
        </p>

        <br />
        <p className="MsoNormal">
          2.1. You shall register to become a user of the Website only if You
          are of the age of 18 or above and can enter into binding contracts as
          per Applicable Laws. You are responsible for maintaining the secrecy
          of Your passwords, login and account information. You will be
          responsible for all use of the Website and/ or Services by You and
          anyone using Your password and login information (with or without our
          permission). You are responsible for maintaining the confidentiality
          of any login information and secure access credentials associated with
          Your{" "}
          <b>
            <u>Zap</u>
          </b>{" "}
          account. Accordingly, You are responsible for all activities that
          occur under Your account/in using Your secure credentials and{" "}
          <b>
            <u>Zap</u>
          </b>{" "}
          shall not be liable for any such change or action performed by using
          Your secure credentials on the Website.
        </p>

        <br />
        <p className="MsoNormal">
          2.2. You also agree to provide true, accurate, current and complete
          information about Yourself as and when prompted by the Zap. If You
          provide any information that is untrue, inaccurate, not updated or
          incomplete (or becomes untrue, inaccurate or incomplete), or
          <b>
            <u>Zap</u>
          </b>{" "}
          has reasonable grounds to suspect that such information is untrue,
          inaccurate, not updated or incomplete,{" "}
          <b>
            <u>Zap</u>
          </b>{" "}
          shall have the right to suspend or terminate Your account and/or
          refuse any and all current or future use of the Website (or any
          portion thereof) or Services in connection thereto. Therefore , you
          are  responsible for providing accurate and up-to-date information to
          Zap during the credit approval process.you must also adhere to the
          agreed-upon repayment schedule and promptly settle outstanding dues to
          avoid any adverse consequences.
        </p>

        <br />
        <p className="MsoNormal">
          2.3  You are responsible for providing accurate and up-to-date
          information to Zap during the credit approval process. You must also
          adhere to the agreed-upon repayment schedule and promptly settle
          outstanding dues to avoid any adverse consequences.
        </p>

        <br />
        <p className="MsoNormal">
          <b>
            <u>3. PRIVACY POLICY</u>
          </b>
        </p>

        <br />
        <p className="MsoNormal">
          3.1 Zap values the privacy and security of your information.
        </p>

        <br />
        <p className="MsoNormal">
          3.2 you acknowledge and consent to the collection, storage, and
          processing of your personal data by Zap for the purpose of providing
          credit services. Zap implements reasonable security measures to
          protect your data, but you should also take precautions to safeguard
          their login credentials and personal information.
        </p>

        <br />
        <p className="MsoNormal">
          <b>
            <u>4. INTELLECTUAL PROPERTY</u>
          </b>
        </p>

        <br />
        <p className="MsoNormal">
          4.1 software subscriptions purchases through zap are and will remain
          the exclusive property of Zap. and its partnered SaaS companies .
        </p>

        <br />
        <p className="MsoNormal">
          4.2 this  is protected by copyright, trademark, and other laws of the
          India. Our trademarks and trade dress may not be used in connection
          with any product or service without the prior written consent of Zap.
        </p>

        <br />
        <p className="MsoNormal">
          4.3 you are granted a non-transferable and non-exclusive license to
          use the software during the subscription period.
        </p>

        <br />
        <p>
          <b>
            <u>5. LIMITATION OF LIABILITY</u>
          </b>
        </p>

        <br />
        <p>
          5.1. Zap including its officers, directors, employees,
          representatives, affiliates, and providers will not be responsible or
          liable for (a) any injury, death, loss, claim, act of God, accident,
          delay, or any direct, special, exemplary, punitive, indirect,
          incidental or consequential damages of any kind (including without
          limitation lost profits or lost savings), whether based in contract,
          tort, strict liability or otherwise, that arise out of or is in any
          way connected with its services or ay issues  related to software
          subscriptions purchased through Zap.
        </p>

        <br />
        <p>
          <b>
            <u>6. DISPUTE RESOLUTION</u>
          </b>
        </p>

        <br />
        <p>
          6.1  Any disputes  shall be resolved amicably through negotiation and
          dialogue. If no resolution can be reached, the parties agree to submit
          to the exclusive jurisdiction of the competent courts.
        </p>

        <br />
        <p>
          <b>
            <u>7.GOVERNING LAW</u>
          </b>
        </p>

        <br />
        <p>
          7.1 These Terms of Service shall be governed by and construed in
          accordance with the laws of the jurisdiction in which Zap operates,
          without regard to its conflict of law provisions.Our failure to
          enforce any right or provision of these Terms will not be considered a
          waiver of those rights. If any provision of these Terms is held to be
          invalid or unenforceable by a court, the remaining provisions of these
          Terms will remain in effect. These Terms constitute the entire
          agreement between us regarding our Service and supersede and replace
          any prior agreements we might have had between us regarding Service.
        </p>

        <br />
        <p>
          <b>
            <u>8. TERMINATION</u>
          </b>
        </p>

        <br />
        <p>
          8.1 We may terminate or suspend your account and bar access to Service
          immediately, without prior notice or liability, under our sole
          discretion, for any reason whatsoever and without limitation,
          including but not limited to a breach of Terms. If you wish to
          terminate your account, you may simply discontinue using Service. All
          provisions of Terms which by their nature should survive termination
          shall survive termination, including, without limitation, ownership
          provisions, warranty disclaimers, indemnity and limitations of
          liability.
        </p>

        <br />
        <p>
          <b>
            <u>9. MODIFICATION</u>
          </b>
        </p>

        <br />
        <p>
          9.1 We may modify and amend the Terms at any time by posting the
          modified  terms on this site. You  will be notified of any changes,
          and continued use of Zap's services after such notification
          constitutes acceptance of the modified terms.It is your responsibility
          to review these Terms periodically
        </p>

        <br />
        <p>
          <b>
            <u>10. REFUND AND CANCELLATION POLICY</u>
          </b>
        </p>

        <br />
        <p>
          10.1 Once a software subscription is purchased, it shall be considered
          final and binding. Amendments or modifications to the subscription are
          generally not possible, as Zap advances the purchase value to the
          partnered SaaS company. However, you have the option to request the
          cancellation of an active subscription.
        </p>
        <br />
        <p>
          10.2 The decision to approve a cancellation request is at the
          discretion of Zap, in consultation with the partnered SaaS company. If
          a cancellation is granted, you may be subject to a pre-closure fee,
          and they will be required to repay the prorated amount corresponding
          to any discount enjoyed during the initial purchase.
        </p>
        <br />
        <p>
          10.3 To request a refund or cancellation, customers can contact Zap's
          support team by writing an email to support@paywithzap.com. Zap will
          review each request on a case-by-case basis. The processing of the
          request will typically be completed within 7-14 working days, and a
          final decision will be made after consultation with the SaaS partner.
        </p>
        <br />
        <p>
          10.4 If a cancellation request is approved, the customer's outstanding
          balance will be reduced to zero after payment of relevant charges,
          including cancellation and processing fees, late payment charges, and
          any other applicable charges levied by both the SaaS partner and Zap.
        </p>
        <br />
        <p>
          10.5 It is important to note that the ultimate decision to cancel a
          subscription lies with the SaaS partner. However, Zap will make every
          effort to advocate for the customer's request and work towards a
          resolution with the SaaS partner.
        </p>
        <br />
        <p>
          10.6 Please note that this refund and cancellation policy is subject
          to the terms and conditions outlined in the original agreement between
          the customer, Zap, and the partnered SaaS company.
        </p>
        <br />
        <p>
          10.7 If you have any further questions or concerns regarding the
          refund and cancellation process, please reach out to our support team
          at{" "}
          <b>
            <u>
              <a href="mailto:support@paywithzap.com">support@paywithzap.com</a>
            </u>
          </b>
          .
        </p>

        <br />
        <p>
          <b>
            <u>
              11. CUSTOMER OBLIGATION, SUBSCRIPTION RETRACTION AND GRACE PERIOD
              EXTENSION:
            </u>
          </b>
        </p>

        <br />
        <p>
          11.1 you are responsible for providing accurate and up-to-date
          information to Zap during the credit approval process.
        </p>
        <br />
        <p>
          11.2 you must adhere to the agreed-upon repayment schedule and
          promptly settle outstanding dues.
        </p>
        <br />
        <p>
          11.3 In the event of a missed repayment exceeding 14 days, Zap
          reserves the right to retract the your subscription. Zap will make
          efforts to follow up with the you through various means of
          communication, including calls, email reminders, etc., to encourage
          you to resume repayment. However, in the case of willful default or
          extended non-payment, the your subscription may be retracted.
        </p>
        <br />
        <p>
          11.4 In cases where your encounter unforeseen financial difficulties,
          they have the option to request an extension of the grace period from
          Zap. you can submit a genuine request to Zap, explaining their
          circumstances. Zap will evaluate such requests on a case-by-case
          basis, taking into consideration the genuineness of the request and
          the your overall repayment history.
        </p>
        <br />
        <p>
          11.5 If the request is deemed valid, Zap may consider granting an
          extension of the grace period to allow your additional time to fulfill
          their repayment obligations.
        </p>

        <br />
        <p>
          <b>
            <u>12. COLLABORATION WITH NBFC’S AND LENDING PARTNERS:</u>
          </b>
        </p>

        <br />
        <p>
          12.1 Zap collaborates with other Non-Banking Financial Companies
          (NBFCs) and lending partners, both domestically and globally, to
          facilitate the provision of credit services. It is important to note
          that while Zap enables and underwrites the credit for the purchase of
          software subscriptions, the actual issuance of credit is performed by
          these partnering NBFCs and lending institutions.
        </p>

        <br />
        <p>
          <b>
            <u>13. CUSTOMER DASHBOARD AND CREDIT LINE</u>
          </b>
        </p>

        <br />
        <p>
          13.1 Customers using Zap's services will have access to a
          comprehensive customer dashboard. This dashboard provides insights
          into their spending, including transaction history, repayment status,
          and other relevant information.
        </p>
        <br />
        <p>
          13.2 Additionally, eligible customers may be offered a credit line
          that can be utilized not only for purchases on partnered websites but
          also for transactions with other SaaS vendors. The availability and
          terms of the credit line may vary based on the customer's
          creditworthiness and the partnering lending institutions.
        </p>

        <br />
        <p>
          <b>
            <u>14. WAIVER AND SEVERABILITY</u>
          </b>
        </p>

        <br />
        <p>
          14.1 No waiver by Company of any term or condition set forth in Terms
          shall be deemed a further or continuing waiver of such term or
          condition or a waiver of any other term or condition, and any failure
          of Company to assert a right or provision under Terms shall not
          constitute a waiver of such right or provision. If any provision of
          Terms is held by a court or other tribunal of competent jurisdiction
          to be invalid, illegal or unenforceable for any reason, such provision
          shall be eliminated or limited to the minimum extent such that the
          remaining provisions of Terms will continue in full force and effect.
        </p>

        <br />
        <p>
          <b>
            <u>15. ACKNOWLEDGEMENT</u>
          </b>
        </p>

        <br />
        <p>
          15.1 BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU
          ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE
          BOUND BY THEM.
        </p>

        <br />
        <p>
          <b>
            <u>16. CONTACT US</u>
          </b>
        </p>

        <br />
        <p>
          Please send your feedback, comments, requests for technical support:
          By email:{" "}
          <b>
            <u>
              <a href="mailto: support@paywithzap.com">
                support@paywithzap.com
              </a>
            </u>
          </b>
        </p>
        <Text className="MsoNormal"></Text>
      </Box>
    </Box>
  );
}

export default Terms;
