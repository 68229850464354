import React from "react";
import {
  Box,
  Button,
  CloseButton,
  Flex,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import Hero from "./Hero";
import { useLocation, useNavigate } from "react-router-dom";
import Metrics from "./Metrics";
import WinWin from "./WinWin";
import UpliftMetrics from "./UpliftMetrics";
import SuperchargeRevenue from "./SuperchargeRevenue";
import ParticipantCompanies from "../Customer/participants";
import FaqCustomer from "../../Components/Faq/FaqCustomer";
import { regionData } from "../../utils/Region";
import axios from "axios";
import { useCombobox } from "downshift";

function Partner({ partnerFaq }: { partnerFaq: any[] | null }) {
  // useNavigate
  const navigate = useNavigate();

  // useLocation
  const { pathname } = useLocation();

  // useState
  const [showDropDown, setShowDropDown] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [regions, setRegions] = React.useState(regionData);

  // function
  const logoOnClick = () => navigate(pathname);
  const handleDropDown = () => setShowDropDown((prev) => !prev);

  // useCombobox
  const {
    isOpen,
    selectedItem,
    reset,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: regions,
    onInputValueChange: ({ inputValue }) => {
      setSearchValue(inputValue || "");

      if (inputValue)
        setRegions(
          regionData.filter((item) =>
            item.value.toLowerCase().startsWith(inputValue.toLowerCase())
          )
        );
      else setRegions(regionData);

      return;
    },
    onStateChange({ selectedItem }) {
      if (selectedItem) {
        setSearchValue(selectedItem.label);
        return;
      }

      return;
    },
  });

  return (
    <Box w={"100vw"} overflowX={"hidden"} minH={"100vh"} bg={"#1A0B2E"}>
      <Box
        display={{ md: "none", base: "flex" }}
        h={"80px"}
        alignItems={"center"}
        pos={"relative"}
        px={"22px"}
        justifyContent={"space-between"}
      >
        <button
          onClick={logoOnClick}
          style={{
            backgroundColor: "transparent",
            borderWidth: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Text fontFamily={"Lexend"} fontWeight={"bold"} color={"#fff"}>
            Pay with
          </Text>
          <Image src="svgs/logo.svg" h={"40px"} mt={"-6px"} />
        </button>

        <Flex
          as={"button"}
          borderWidth={0}
          zIndex={100}
          alignItems={"center"}
          justifyContent={"space-between"}
          marginBottom={"10px"}
          w={"126px"}
          px={"12px"}
          borderTopRadius={10}
          borderBottomRadius={showDropDown ? 0 : 10}
          bg={`linear-gradient(147deg, #238148 0%, #4ee089 100%)`}
          h={"35px"}
          onClick={handleDropDown}
        >
          <Text
            fontFamily={"Poppins"}
            fontWeight={"600"}
            fontSize={"14px"}
            color={"#fff"}
          >
            for SaaS
          </Text>
          {/* {showDropDown ? up : down} */}
        </Flex>

        {showDropDown && (
          <Flex
            as={"button"}
            borderWidth={0}
            zIndex={100}
            alignItems={"center"}
            justifyContent={"space-between"}
            marginBottom={"10px"}
            w={"129px"}
            pos={"absolute"}
            top={"52px"}
            right={"20.5px"}
            px={"12px"}
            borderBottomRadius={10}
            bg={`rgba(255, 255, 255, 1)`}
            h={"35px"}
            onClick={() => navigate("/forCustomer")}
          >
            <Text
              color={"#000"}
              fontWeight={"600"}
              fontSize={"14px"}
              fontFamily={"Poppins"}
            >
              for customers
            </Text>
          </Flex>
        )}
      </Box>

      <Hero />
      <Metrics />
      <WinWin />
      <UpliftMetrics />
      <SuperchargeRevenue />
      <ParticipantCompanies />
      {!!partnerFaq && !!partnerFaq?.length && (
        <FaqCustomer customerFaq={partnerFaq} />
      )}

      <Flex
        flexDir={"column"}
        minH={{ base: "100vh", md: "fit-content" }}
        py={{ md: 32, base: 16 }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Flex
          bg={`linear-gradient(90deg, #D735FF 0%, #8729FF 55.73%, #915FFF 95.31%)`}
          rounded={"lg"}
          w={{ base: "90vw", md: "70vw" }}
          alignSelf={"center"}
          alignItems={"center"}
          mb={10}
          id="customer-form"
          flexDir={"column"}
          pt={8}
          pb={2}
          px={2}
        >
          <Heading
            size={{ md: "md", base: "sm" }}
            fontFamily="Lexend"
            textAlign={"center"}
            alignSelf={"center"}
            color={"#fff"}
          >
            REGISTER TO GET EARLY ACCESS
          </Heading>

          <Flex
            flexDir={"column"}
            alignItems={"center"}
            mt={6}
            rounded={"lg"}
            overflow={"hidden"}
            bg={`linear-gradient(135deg, #000000 0%, #4EE089 48.75%, #10552C 77.59%, #000000 100%)`}
            w={"full"}
          >
            <form
              style={{ width: "100%", height: "100%" }}
              onSubmit={(event) => {
                event.preventDefault(); // Prevent the default form submission behavior

                const data = new FormData(event.currentTarget);
                if (!data) return;

                const first_name = data.get("first_name") as string;
                const last_name = data.get("last_name") as string;
                const email = data.get("email") as string;
                const companyName = data.get("companyName") as string;
                const region = data.get("region") as string;
                const phone = data.get("phone") as string;

                axios
                  .post(
                    `https://api.airtable.com/v0/appKff9E3kICA8yCu/SaaS_Partners`,
                    {
                      fields: {
                        Name: `${first_name} ${last_name}`,
                        Email: email,
                        Phone: phone,
                        Country: region,
                        Company: companyName,
                      },
                    },
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_TOKEN}`,
                      },
                    }
                  )
                  .then((e) => alert("Your response has been submitted"))
                  .catch((e) => console.log(e));

                return;
              }}
            >
              <Flex
                flexDir={"column"}
                alignItems={"center"}
                bg={`rgba(0, 0, 0, 0.6)`}
                w={"full"}
                p={8}
              >
                <Heading
                  size={{ md: "md", base: "sm" }}
                  mb={4}
                  fontFamily="Lexend"
                  textAlign={"center"}
                  alignSelf={"center"}
                  color={"#fff"}
                >
                  SPEAK WITH US TO GET AN INSTANT CREDIT LINE FROM ZAP
                </Heading>

                <Flex
                  gap={{ base: 1, md: 4 }}
                  mt={{ base: 1, md: 10 }}
                  justifyContent={"space-between"}
                  w={{ base: "72.5vw", md: "42.5vw" }}
                  flexDir={{ base: "column", md: "row" }}
                  alignSelf={"center"}
                >
                  <Input
                    name="first_name"
                    placeholder="First Name"
                    variant={"filled"}
                    required
                    bg={"rgba(141, 141, 141, 0.2)"}
                    _hover={{ bg: "rgba(141, 141, 141, 0.2)" }}
                    h={12}
                    w={{ base: "72.5vw", md: "21vw" }}
                  />
                  <Input
                    required
                    name="last_name"
                    placeholder="Last Name"
                    variant={"filled"}
                    bg={"rgba(141, 141, 141, 0.2)"}
                    _hover={{ bg: "rgba(141, 141, 141, 0.2)" }}
                    h={12}
                    w={{ base: "72.5vw", md: "21vw" }}
                  />
                </Flex>

                <Flex
                  gap={{ base: 1, md: 4 }}
                  mt={{ base: 1, md: 4 }}
                  justifyContent={"space-between"}
                  w={{ base: "72.5vw", md: "42.5vw" }}
                  flexDir={{ base: "column", md: "row" }}
                  alignSelf={"center"}
                >
                  <Input
                    w={{ base: "72.5vw", md: "21vw" }}
                    name="email"
                    placeholder="Email"
                    variant={"filled"}
                    required
                    bg={"rgba(141, 141, 141, 0.2)"}
                    _hover={{ bg: "rgba(141, 141, 141, 0.2)" }}
                    h={12}
                  />
                  <Input
                    w={{ base: "72.5vw", md: "21vw" }}
                    required
                    name="companyName"
                    placeholder="Company Name"
                    variant={"filled"}
                    bg={"rgba(141, 141, 141, 0.2)"}
                    _hover={{ bg: "rgba(141, 141, 141, 0.2)" }}
                    h={12}
                  />
                </Flex>

                <Flex
                  gap={{ base: 1, md: 4 }}
                  mt={{ base: 1, md: 4 }}
                  justifyContent={"space-between"}
                  w={{ base: "72.5vw", md: "42.5vw" }}
                  flexDir={{ base: "column", md: "row" }}
                  pos={"relative"}
                >
                  <InputGroup w={{ base: "72.5vw", md: "21vw" }} h={12}>
                    {selectedItem && selectedItem.label === searchValue && (
                      <InputLeftElement my={1} mx={2} alignSelf={"center"}>
                        <img
                          src={
                            selectedItem.flags.png
                              ? selectedItem.flags.png
                              : selectedItem.flags.svg
                              ? selectedItem.flags.svg
                              : ""
                          }
                          width={48}
                          height={48}
                          style={{ marginLeft: 20 }}
                          alt={selectedItem.flags.alt || selectedItem.label}
                        />
                      </InputLeftElement>
                    )}

                    {searchValue.length > 0 && (
                      <InputRightElement
                        m={1}
                        alignSelf={"center"}
                        cursor={"pointer"}
                      >
                        <CloseButton size={"sm"} p={1} onClick={reset} />
                      </InputRightElement>
                    )}

                    <Input
                      {...getInputProps({ value: searchValue })}
                      pl={
                        selectedItem && selectedItem.label === searchValue
                          ? "64px"
                          : undefined
                      }
                      required
                      placeholder="Country"
                      name="region"
                      h={12}
                      variant={"filled"}
                      bg={"rgba(141, 141, 141, 0.2)"}
                      _hover={{ bg: "rgba(141, 141, 141, 0.2)" }}
                    />
                  </InputGroup>

                  {isOpen && (
                    <Box
                      as="ul"
                      pos={"absolute"}
                      zIndex={100}
                      top={"64px"}
                      dropShadow={"md"}
                      w={{ base: "72.5vw", md: "21vw" }}
                      bg={"white"}
                      px="2"
                      py="1"
                      minH={"50px"}
                      maxH={"200px"}
                      overflowY={"scroll"}
                      {...getMenuProps()}
                    >
                      {regions.map((item, index) => (
                        <Flex
                          as={"li"}
                          alignItems={"center"}
                          py={"2"}
                          style={
                            highlightedIndex === index
                              ? { backgroundColor: "#bde4ff" }
                              : {}
                          }
                          key={`${item.value}-${index}`}
                          {...getItemProps({ item, index })}
                        >
                          <Image
                            src={
                              item.flags.png
                                ? item.flags.png
                                : item.flags.svg
                                ? item.flags.svg
                                : ""
                            }
                            mx={4}
                            w={"48px"}
                            alt={item.flags.alt ? item.flags.alt : item.label}
                          />
                          {item.label}
                        </Flex>
                      ))}
                    </Box>
                  )}

                  <Input
                    name="phone"
                    required
                    type="number"
                    placeholder="Mobile Number"
                    variant={"filled"}
                    bg={"rgba(141, 141, 141, 0.2)"}
                    _hover={{ bg: "rgba(141, 141, 141, 0.2)" }}
                    h={12}
                    w={{ base: "72.5vw", md: "21vw" }}
                    mt={{ base: 8, md: 0 }}
                  />
                </Flex>

                {/* <div className="vertical">
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder="Email"
                />
                <input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  placeholder="Name"
                />

                <Select
                  options={regions}
                  onChange={(e) => {
                    if (e) setCountry(e.value);
                  }}
                  styles={{
                    input: (styles) => ({ ...styles, width: "100%" }),
                    container: (styles) => ({
                      ...styles,
                      borderRadius: 7.7781,
                      color: "#ffffff50",
                      fontFamily: "Lexend",
                      fontWeight: "300",
                      fontSize: 18,
                      paddingLeft: 24,
                      width: "100%",
                      height: 63.78,
                      marginTop: 16,
                      backgroundColor: "rgba(141, 141, 141, 0.2)",
                    }),
                    dropdownIndicator: () => ({ display: "none" }),
                    option: () => ({
                      backgroundColor: "#fff",
                      padding: 10,
                      opacity: 1,
                      width: "90%",
                    }),
                  }}
                  placeholder="Country"
                  unstyled={true}
                />
                <input
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  placeholder="Mobile Number"
                />
              </div> */}

                <Button
                  mt={10}
                  color={"#fff"}
                  fontSize={{ md: "md", base: "sm" }}
                  fontFamily={"Poppins"}
                  h={{ md: "48px", base: "42px" }}
                  px={"10"}
                  bg={`linear-gradient(90deg, #ff8008 0%, #ffc837 100%)`}
                  _hover={{
                    bg: `linear-gradient(120deg, #ff8008 20%, #ffc837 50%)`,
                  }}
                  type="submit"
                >
                  REQUEST ACCESS
                </Button>
              </Flex>
            </form>
          </Flex>
        </Flex>

        <Text
          alignSelf={"center"}
          textAlign={"center"}
          fontFamily={"GothamLight"}
          fontWeight={"book"}
          w={{ base: "90vw", md: "50vw" }}
          color={"#fff"}
          mt={{ md: 4, base: 0 }}
          fontSize={{ md: `calc(50vw * 0.0275)`, base: `calc(90vw * 0.04)` }}
        >
          We’re currently accepting registrations only on an invite only basis.
          Request an invite using this form
        </Text>
      </Flex>
    </Box>
  );
}

export default Partner;
