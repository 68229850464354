import React from "react";
import "./HowItWorks.scss";
import {
  Box,
  Flex,
  GridItem,
  Heading,
  Image,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import MotionWrap from "../../utils/MotionWrap";

const tempArr = [
  {
    alt: "tick",
    src: "/svgs/HowItWorks/tick.svg",
    title: "1. Select an annual plan",
    desc: "Choose an annual plan from a participating SaaS company",
  },
  {
    alt: "percentage",
    src: "/svgs/HowItWorks/percentage.svg",
    title: "2. Choose to pay with Zap",
    desc: "Choose to pay using Zap at the time of checkout",
  },
  {
    alt: "wallet",
    src: "/svgs/HowItWorks/wallet.svg",
    title: "3. Pay monthly",
    desc: "Enjoy the annual discount and pay Zap on a monthly basis",
  },
];

const progress = ["1.svg", "2.svg", "3.svg"];

const Header_element = () => (
  <motion.div
    initial={{ y: 100 }}
    whileInView={{ y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
    style={{ alignItems: "center", display: "flex", flexDirection: "column" }}
  >
    {/* <Heading
      size={{ base: "xl", md: "3xl" }}
      fontFamily={"Lexend"}
      className="gradient-text"
    > */}
    <Heading
      fontSize={{ base: "2.5rem", md: "112px" }}
      lineHeight={{ base: "2.5rem", md: "102%" }}
      fontFamily={"Lexend"}
      fontWeight={"900"}
      textAlign={"center"}
      className="gradient-text"
    >
      How it works?
    </Heading>
    <Heading
      fontFamily={"Space Mono"}
      // size={{ base: "sm", md: "md" }}
      size={{ base: "sm", md: "lg" }}
      color={"#fff"}
      opacity={0.8}
      fontStyle={"italic"}
      mt={{ base: 1, md: 0 }}
    >
      “As simple as 1 - 2 - 3”
    </Heading>
  </motion.div>
);

const Grid_element = () => (
  <SimpleGrid
    borderColor={"#4EE089"}
    borderWidth={{ md: 3 }}
    borderStyle={"dashed"}
    rounded={"lg"}
    p={8}
    columns={3}
    mt={20}
    boxShadow={{ md: `0px 0px 11px 0px #82C503` }}
    gap={20}
  >
    {tempArr.map((item) => (
      <Flex
        as={GridItem}
        w={{ base: "80vw", md: "22.5vw" }}
        flexDir={"column"}
        alignItems={"center"}
        colSpan={{ md: 1, base: 3 }}
      >
        <Image src={item.src} w={{ md: `calc(20vw * 0.5)`, base: "20vw" }} />
        <Heading
          mt={2}
          w={{ md: "70%", base: "70%" }}
          textAlign="center"
          h={{ md: "80px" }}
          size={{ md: "lg", base: "lg" }}
          className="gradient-text"
          fontFamily="Lexend"
        >
          {item.title}
        </Heading>
        <Text
          mt={1}
          fontFamily="Poppins"
          textAlign={"center"}
          color="#fff"
          opacity={0.8}
        >
          {item.desc}
        </Text>
      </Flex>
    ))}
  </SimpleGrid>
);

const Footer_element = () => (
  <Heading
    fontSize={{ md: "40px" }}
    color={"#fff"}
    w="95vw"
    textAlign={"center"}
    fontFamily={"GothamUltra"}
    mb={20}
    letterSpacing={"1.5px"}
  >
    ✨ You’re all set to get the{" "}
    <span className="gradient-text-secondary">annual discount</span>
    <br />
    while <span className="gradient-text-secondary">
      still paying monthly
    </span>{" "}
    ✨
  </Heading>
);

const RenderFooter = MotionWrap(Footer_element, "");
const RenderGrid = MotionWrap(Grid_element, "");
const RenderHeader = MotionWrap(Header_element, "");

function HowItWorks() {
  // useState
  const [top, setTop] = React.useState(0);
  const [index, setIndex] = React.useState(0);
  const [dynamicHeight, setDynamicHeight] = React.useState(0);

  // useRef
  const videoRef_1 = React.useRef<HTMLVideoElement>(null);
  const videoRef_2 = React.useRef<HTMLVideoElement>(null);
  const videoRef_3 = React.useRef<HTMLVideoElement>(null);

  const objectRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (objectRef.current) {
      const vw = window.innerWidth;
      const vh = window.innerHeight;

      setDynamicHeight(objectRef.current.scrollWidth - vw + vh + 250);
    }
  }, []);

  // functions
  const scrollHandler = React.useCallback(() => {
    if (containerRef.current) {
      const offsetTop = containerRef.current.offsetTop;
      setTop(offsetTop);
      const width = window.innerWidth;

      if (offsetTop < width * 0.6) {
        setIndex(0);
      } else if (offsetTop < width * 0.65 * 2) {
        setIndex(1);
      } else {
        setIndex(2);
      }
    }
  }, []);

  // useEffect
  React.useEffect(() => {
    switch (true) {
      case index === 0:
        videoRef_1.current?.play();
        videoRef_2.current?.pause();
        videoRef_3.current?.pause();
        break;

      case index === 1:
        videoRef_1.current?.pause();
        videoRef_2.current?.play();
        videoRef_3.current?.pause();
        break;

      default:
        videoRef_1.current?.pause();
        videoRef_2.current?.pause();
        videoRef_3.current?.play();
        break;
    }
  }, [index]);

  React.useEffect(() => {
    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [scrollHandler]);

  return (
    <Flex
      flexDir={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      minH={"100vh"}
      pt={"100px"}
      pb={"48px"}
    >
      <RenderHeader />

      <Tabs
        display={{ md: "flex", base: "none" }}
        flexDir={"column"}
        minH={"100vh"}
        w={"full"}
        mt={10}
        variant="unstyled"
      >
        <TabList
          alignSelf={"flex-end"}
          w={"392px"}
          h={"64px"}
          borderRadius={"20px"}
          backgroundColor={"#373737"}
          mx={"96px"}
        >
          <Tab
            _selected={{
              color: "white",
              bg: `linear-gradient(147deg, #238148 0%, #4EE089 100%)`,
              WebkitTextFillColor: "#fff",
            }}
            fontFamily={"Poppins"}
            fontWeight={"700"}
            letterSpacing={"-1.2px"}
            className="gradient-text"
            w={"50%"}
            fontSize={"20px"}
            borderRadius={"20px"}
          >
            Extensive
          </Tab>
          <Tab
            _selected={{
              color: "white",
              bg: `linear-gradient(147deg, #238148 0%, #4EE089 100%)`,
              WebkitTextFillColor: "#fff",
            }}
            fontFamily={"Poppins"}
            fontWeight={"700"}
            letterSpacing={"-1.2px"}
            className="gradient-text"
            w={"50%"}
            fontSize={"20px"}
            borderRadius={"20px"}
          >
            Minimal
          </Tab>
        </TabList>

        <TabPanels mt={"48px"} pos={"relative"} w={"full"}>
          <TabPanel p={0} h={`${dynamicHeight}px`} pos={"relative"} w={"full"}>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              ref={containerRef}
              h={"100vh"}
              top={0}
              overflowX={"hidden"}
              w={"full"}
              pos={"sticky"}
            >
              <Flex
                w={"full"}
                flexDir={"column"}
                pos={"absolute"}
                h={"full"}
                justifyContent={"center"}
                alignItems={"center"}
                willChange={"transform"}
                ref={objectRef}
                pt={"90px"}
              >
                <Flex
                  alignItems={"flex-start"}
                  alignSelf={"center"}
                  maxH={"10vh"}
                  flexDir={"column"}
                >
                  <Flex
                    as={motion.div}
                    animate={
                      index === 0
                        ? { opacity: 1, y: 0 }
                        : { opacity: 0, y: 110 }
                    }
                    initial={{ opacity: 0, y: 110 }}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Flex
                      alignItems={"center"}
                      justifyContent={"center"}
                      w={"10vh"}
                      h={"10vh"}
                      pos={"relative"}
                      transition={"all 0.2s ease-in-out"}
                    >
                      <Image
                        src={`/svgs/HowItWorks/${progress[index]}`}
                        h={"10vh"}
                        pos={"absolute"}
                        top={0}
                        right={0}
                      />
                      <Text
                        color={"#fff"}
                        fontSize={`calc(10vh * 0.5)`}
                        fontFamily={"Orelega One"}
                      >
                        1
                      </Text>
                    </Flex>

                    <Heading
                      ml={10}
                      textAlign={"center"}
                      color={"#fff"}
                      fontFamily={"Poppins"}
                      fontSize={`calc(10vh * 0.45)`}
                    >
                      Choose an <br />
                      annual plan
                    </Heading>
                  </Flex>

                  <Flex
                    as={motion.div}
                    animate={
                      index === 1
                        ? { y: -110, opacity: 1 }
                        : { y: -10, opacity: 0 }
                    }
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Flex
                      alignItems={"center"}
                      justifyContent={"center"}
                      w={"10vh"}
                      h={"10vh"}
                      pos={"relative"}
                      transition={"all 0.2s ease-in-out"}
                    >
                      <Image
                        src={`/svgs/HowItWorks/${progress[1]}`}
                        h={"10vh"}
                        pos={"absolute"}
                        top={0}
                        right={0}
                      />
                      <Text
                        color={"#fff"}
                        fontSize={`calc(10vh * 0.5)`}
                        fontFamily={"Orelega One"}
                      >
                        2
                      </Text>
                    </Flex>

                    <Heading
                      ml={10}
                      textAlign={"center"}
                      color={"#fff"}
                      fontFamily={"Poppins"}
                      fontSize={`calc(10vh * 0.45)`}
                    >
                      Choose <Text className="gradient-text">Zap</Text>
                    </Heading>
                  </Flex>

                  <Flex
                    as={motion.div}
                    animate={
                      index === 2
                        ? { y: -210, opacity: 1 }
                        : { y: -110, opacity: 0 }
                    }
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Flex
                      alignItems={"center"}
                      justifyContent={"center"}
                      w={"10vh"}
                      h={"10vh"}
                      pos={"relative"}
                      transition={"all 0.2s ease-in-out"}
                    >
                      <Image
                        src={`/svgs/HowItWorks/${progress[2]}`}
                        h={"10vh"}
                        // w={"10vh"}
                        pos={"absolute"}
                        top={0}
                        right={0}
                      />
                      <Text
                        color={"#fff"}
                        fontSize={`calc(10vh * 0.5)`}
                        fontFamily={"Orelega One"}
                      >
                        {3}
                      </Text>
                    </Flex>
                    <Heading
                      ml={10}
                      textAlign={"center"}
                      color={"#fff"}
                      fontFamily={"Poppins"}
                      fontSize={`calc(10vh * 0.45)`}
                    >
                      Pay monthly
                    </Heading>
                  </Flex>
                </Flex>

                <motion.div
                  style={{
                    width: "100%",
                    marginTop: "5vh",
                  }}
                  animate={{ x: -top }}
                  transition={{ duration: 0 }}
                >
                  <Flex
                    flexDir={"row"}
                    alignItems={"center"}
                    w={"full"}
                    gap={"5vw"}
                    px={"15vw"}
                  >
                    <Box w={"70vw"}>
                      <Flex w={"70vw"} alignItems={"center"} flexDir={"column"}>
                        <video
                          style={{
                            height: "60vh",
                            borderRadius: "36px",
                            marginBottom: "2.5vh",
                          }}
                          ref={videoRef_1}
                          src="/videos/step_1.mp4"
                          muted
                          loop
                        />

                        <Text
                          fontFamily={"Lexend"}
                          fontWeight={"500"}
                          letterSpacing={"-1.92px"}
                          color={"#fff"}
                          textAlign={"center"}
                          fontSize={`calc(10vh * 0.325)`}
                        >
                          Select an annual plan from a SaaS company
                        </Text>
                      </Flex>
                    </Box>

                    <Box w={"70vw"}>
                      <Flex w={"70vw"} alignItems={"center"} flexDir={"column"}>
                        <video
                          ref={videoRef_2}
                          style={{
                            height: "60vh",
                            borderRadius: "36px",
                            marginBottom: "2.5vh",
                          }}
                          src="/videos/step_2.mp4"
                          muted
                          loop
                        />

                        <Text
                          fontFamily={"Lexend"}
                          fontWeight={"500"}
                          letterSpacing={"-1.92px"}
                          color={"#fff"}
                          textAlign={"center"}
                          fontSize={`calc(10vh * 0.325)`}
                        >
                          Opt to pay with Zap at the time of checkout
                        </Text>
                      </Flex>
                    </Box>

                    <Box w={"70vw"}>
                      <Flex w={"70vw"} alignItems={"center"} flexDir={"column"}>
                        <video
                          style={{
                            height: "60vh",
                            borderRadius: "36px",
                            marginBottom: "2.5vh",
                          }}
                          ref={videoRef_3}
                          src="/videos/step_3.mp4"
                          muted
                          loop
                        />

                        <Text
                          fontFamily={"Lexend"}
                          fontWeight={"500"}
                          letterSpacing={"-1.92px"}
                          color={"#fff"}
                          textAlign={"center"}
                          fontSize={`calc(10vh * 0.325)`}
                        >
                          Initiate the subscription by paying first month's
                          installment
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                </motion.div>
              </Flex>
            </Flex>
          </TabPanel>

          <TabPanel
            as={Flex}
            h={"full"}
            flexDir={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <RenderGrid />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Flex
        h={"full"}
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        display={{ md: "none" }}
      >
        <RenderGrid />
      </Flex>
      <RenderFooter />
    </Flex>
  );
}

export default React.memo(HowItWorks);
