import React from "react";
import "./FormCustomer.scss";
import axios from "axios";
import Select from "react-select";
import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import { regionData } from "../../utils/Region";

function FormCustomer() {
  // useState
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [country, setCountry] = React.useState("");

  const onClick = () => {
    axios
      .post(
        `https://api.airtable.com/v0/appKff9E3kICA8yCu/Customers`,
        {
          fields: {
            Name: name,
            Email: email,
            Phone: phone,
            Country: country,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_TOKEN}`,
          },
        }
      )
      .then((e) => alert("Your response has been submitted"))
      .catch((e) => console.log(e));
  };

  const regions = React.useMemo(() => {
    const promise = regionData.map((item) => ({
      value: item.value,
      label: (
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            color: "#000",
          }}
        >
          <img
            src={
              item.flags.png
                ? item.flags.png
                : item.flags.svg
                ? item.flags.svg
                : ""
            }
            style={{ width: 48, marginRight: 20 }}
            alt={item.flags.alt ? item.flags.alt : item.label}
          />
          {item.label}
        </div>
      ),
    }));

    return promise;
  }, []);

  React.useEffect(() => {
    const scrollToElement = () => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          setTimeout(() => {
            element.scrollIntoView({ behavior: "smooth" });
          }, 1000);
        }
      }
    };

    scrollToElement();

    return () => {};
  }, []);

  return (
    <Flex
      flexDir={"column"}
      minH={{ base: "100vh", md: "fit-content" }}
      py={{ md: 32, base: 16 }}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Flex
        bg={`linear-gradient(90deg, #D735FF 0%, #8729FF 55.73%, #915FFF 95.31%)`}
        rounded={"lg"}
        w={{ base: "90vw", md: "70vw" }}
        alignSelf={"center"}
        alignItems={"center"}
        mb={10}
        id="customer-form"
        flexDir={"column"}
        pt={8}
        pb={2}
        px={2}
      >
        <Heading
          size={{ md: "md", base: "sm" }}
          fontFamily="Lexend"
          textAlign={"center"}
          alignSelf={"center"}
          color={"#fff"}
        >
          REGISTER TO GET EARLY ACCESS
        </Heading>

        <Flex
          flexDir={"column"}
          alignItems={"center"}
          mt={6}
          rounded={"lg"}
          overflow={"hidden"}
          bg={`linear-gradient(135deg, #000000 0%, #4EE089 48.75%, #10552C 77.59%, #000000 100%)`}
          w={"full"}
        >
          <Flex
            flexDir={"column"}
            alignItems={"center"}
            bg={`rgba(0, 0, 0, 0.6)`}
            w={"full"}
            p={8}
          >
            <Heading
              size={{ md: "md", base: "sm" }}
              mb={4}
              fontFamily="Lexend"
              textAlign={"center"}
              alignSelf={"center"}
              color={"#fff"}
            >
              SPEAK WITH US TO GET AN INSTANT CREDIT LINE FROM ZAP
            </Heading>

            <div
              style={{
                marginTop: 40,
                width: "42.5vw",
                justifyContent: "space-between",
              }}
              className="horizontal"
            >
              <input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="Email"
              />
              <input
                onChange={(e) => setName(e.target.value)}
                value={name}
                placeholder="Name"
              />
            </div>

            <div
              style={{
                marginTop: 20,
                width: "42.5vw",
                justifyContent: "space-between",
              }}
              className="horizontal"
            >
              {/* <input
            onChange={(e) => setCountry(e.target.value)}
            value={country}
            placeholder="Country"
          /> */}
              <Select
                options={regions}
                onChange={(e) => {
                  console.log(e);
                  if (e) setCountry(e.value);
                }}
                styles={{
                  input: (styles) => ({ ...styles, width: "90%" }),
                  container: (styles) => ({
                    ...styles,
                    borderRadius: 5.92869,
                    color: "#ffffff30",
                    fontFamily: "Lexend",
                    fontWeight: "300",
                    fontSize: 18,
                    paddingLeft: 24,
                    width: "20vw",
                    height: 63.78,
                    backgroundColor: "rgba(141, 141, 141, 0.2)",
                  }),
                  dropdownIndicator: () => ({ display: "none" }),
                  option: () => ({
                    backgroundColor: "#fff",
                    padding: 10,
                    opacity: 1,
                    width: "90%",
                  }),
                }}
                placeholder="Country"
                unstyled={true}
              />
              <input
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                placeholder="Mobile Number"
              />
            </div>

            <div className="vertical">
              <input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="Email"
              />
              <input
                onChange={(e) => setName(e.target.value)}
                value={name}
                placeholder="Name"
              />

              <Select
                options={regions}
                onChange={(e) => {
                  console.log(e);
                  if (e) setCountry(e.value);
                }}
                styles={{
                  input: (styles) => ({ ...styles, width: "100%" }),
                  container: (styles) => ({
                    ...styles,
                    borderRadius: 7.7781,
                    color: "#ffffff50",
                    fontFamily: "Lexend",
                    fontWeight: "300",
                    fontSize: 18,
                    paddingLeft: 24,
                    width: "100%",
                    height: 63.78,
                    marginTop: 16,
                    backgroundColor: "rgba(141, 141, 141, 0.2)",
                  }),
                  dropdownIndicator: () => ({ display: "none" }),
                  option: () => ({
                    backgroundColor: "#fff",
                    padding: 10,
                    opacity: 1,
                    width: "90%",
                  }),
                }}
                placeholder="Country"
                unstyled={true}
              />
              <input
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                placeholder="Mobile Number"
              />
            </div>

            <Button
              mt={10}
              color={"#fff"}
              fontSize={{ md: "md", base: "sm" }}
              fontFamily={"Poppins"}
              h={{ md: "48px", base: "42px" }}
              px={"10"}
              bg={`linear-gradient(90deg, #ff8008 0%, #ffc837 100%)`}
              _hover={{
                bg: `linear-gradient(120deg, #ff8008 20%, #ffc837 50%)`,
              }}
              onClick={onClick}
            >
              REQUEST ACCESS
            </Button>
          </Flex>
        </Flex>
      </Flex>

      <Text
        alignSelf={"center"}
        textAlign={"center"}
        fontFamily={"GothamLight"}
        fontWeight={"book"}
        w={{ base: "90vw", md: "50vw" }}
        color={"#fff"}
        mt={{ md: 4, base: 0 }}
        fontSize={{ md: `calc(50vw * 0.0275)`, base: `calc(90vw * 0.04)` }}
      >
        We’re currently accepting registrations only on an invite only basis.
        Request an invite using this form
      </Text>
    </Flex>
  );
}

export default FormCustomer;
