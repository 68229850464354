import { Divider, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";

function Metrics() {
  return (
    <Flex as={"section"} alignItems={"center"} flexDir={"column"} w={"100vw"}>
      <Heading
        display={{ md: "flex", base: "none" }}
        className="gradient-text-secondary"
        fontSize={"2.75rem"}
        fontWeight={"700"}
        fontFamily={"Lexend"}
        mb={12}
      >
        Boost operating metrics
      </Heading>

      <Flex
        alignItems={"center"}
        bg={
          "linear-gradient(117deg, rgba(87, 167, 110, 0.33) 0%, rgba(40, 108, 64, 0.26) 66.14%, rgba(16, 79, 41, 0.22) 100%)"
        }
        justifyContent={"center"}
        w={"100vw"}
        h={{ base: 140, md: 300 }}
        padding={{ base: 4, md: 10 }}
      >
        <Flex
          px={{ base: 2, md: 16 }}
          py={{ base: 4, md: 16 }}
          w={"33vw"}
          flexDir={"column"}
          alignItems={"center"}
          h={"full"}
          gap={{ md: 8, base: 2 }}
        >
          <Heading
            fontFamily={"Lexend"}
            fontWeight={"700"}
            size={{ base: "lg", md: "2xl" }}
            className="gradient-text-secondary"
          >
            +20%
          </Heading>

          <Text
            alignSelf={"center"}
            textAlign={"center"}
            fontFamily={"Lexend"}
            color={"#fff"}
            fontWeight={"700"}
            fontSize={{ base: "xs", md: "xl" }}
          >
            Boosted Retention
          </Text>
        </Flex>

        <Divider orientation="vertical" h={"full"} />

        <Flex
          px={{ base: 2, md: 16 }}
          py={{ base: 4, md: 16 }}
          w={"33vw"}
          flexDir={"column"}
          alignItems={"center"}
          h={"full"}
          gap={{ md: 8, base: 2 }}
        >
          <Heading
            fontFamily={"Lexend"}
            fontWeight={"700"}
            size={{ base: "lg", md: "2xl" }}
            className="gradient-text-secondary"
          >
            +30%
          </Heading>

          <Text
            alignSelf={"center"}
            textAlign={"center"}
            fontFamily={"Lexend"}
            color={"#fff"}
            fontWeight={"700"}
            fontSize={{ base: "xs", md: "xl" }}
          >
            Revenue growth
          </Text>
        </Flex>

        <Divider orientation="vertical" h={"full"} />

        <Flex
          px={{ base: 2, md: 16 }}
          py={{ base: 4, md: 16 }}
          w={"33vw"}
          flexDir={"column"}
          alignItems={"center"}
          h={"full"}
          gap={{ md: 8, base: 2 }}
        >
          <Heading
            fontFamily={"Lexend"}
            fontWeight={"700"}
            size={{ base: "lg", md: "2xl" }}
            className="gradient-text-secondary"
          >
            -27%
          </Heading>

          <Text
            alignSelf={"center"}
            textAlign={"center"}
            fontFamily={"Lexend"}
            color={"#fff"}
            fontWeight={"700"}
            fontSize={{ base: "xs", md: "xl" }}
          >
            Reduction in conversion time
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Metrics;
