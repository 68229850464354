import React from "react";
import "./FaqCustomer.scss";
import { Flex, Heading, Text } from "@chakra-ui/react";

const Plus = () => (
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0614 13.038H12.3991V17.8678C12.3991 18.1239 12.3009 18.3696 12.126 18.5508C11.9511 18.7319 11.7139 18.8337 11.4666 18.8337C11.2193 18.8337 10.9822 18.7319 10.8073 18.5508C10.6324 18.3696 10.5342 18.1239 10.5342 17.8678V13.038H5.87191C5.62461 13.038 5.38743 12.9362 5.21256 12.755C5.03769 12.5739 4.93945 12.3282 4.93945 12.072C4.93945 11.8158 5.03769 11.5701 5.21256 11.389C5.38743 11.2078 5.62461 11.1061 5.87191 11.1061H10.5342V6.27626C10.5342 6.02007 10.6324 5.77438 10.8073 5.59323C10.9822 5.41207 11.2193 5.3103 11.4666 5.3103C11.7139 5.3103 11.9511 5.41207 12.126 5.59323C12.3009 5.77438 12.3991 6.02007 12.3991 6.27626V11.1061H17.0614C17.3087 11.1061 17.5459 11.2078 17.7207 11.389C17.8956 11.5701 17.9938 11.8158 17.9938 12.072C17.9938 12.3282 17.8956 12.5739 17.7207 12.755C17.5459 12.9362 17.3087 13.038 17.0614 13.038Z"
      fill="white"
    />
  </svg>
);

const Minus = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5653 13.261H12.903H11.9705C11.9705 12.8896 12.2179 13.261 11.9705 13.261C12.2179 13.261 12.2179 13.261 11.9705 13.261C11.7232 13.261 11.7232 13.261 11.9705 13.261C11.7232 13.261 11.9705 13.0608 11.9705 13.261H11.0381H6.37581C6.12851 13.261 5.89134 13.1592 5.71647 12.978C5.5416 12.7969 5.44336 12.5512 5.44336 12.295C5.44336 12.0388 5.5416 11.7931 5.71647 11.612C5.89134 11.4308 6.12851 11.3291 6.37581 11.3291H11.0381L7.51083 11.3289C8.22755 11.329 13.0909 11.3291 12.903 11.3291C12.2666 12.5297 12.0891 12.4663 11.9705 11.329C12.2179 11.329 11.1634 12.1998 11.0381 11.3291C11.213 11.5102 12.903 11.3291 7.10469 11.3291H12.903H17.5653C17.8126 11.3291 18.0498 11.4308 18.2246 11.612C18.3995 11.7931 18.4977 12.0388 18.4977 12.295C18.4977 12.5512 18.3995 12.7969 18.2246 12.978C18.0498 13.1592 17.8126 13.261 17.5653 13.261Z"
      fill="white"
    />
  </svg>
);

const SelectedArrow = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.90625 4.82617L15.5975 12.5175L7.90625 20.2087"
      stroke="white"
      stroke-width="1.92282"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const DefaultArrow = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.90576 4.47363L15.597 12.1649L7.90576 19.8562"
      stroke="#A5A6F6"
      stroke-width="1.92282"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

function FaqCustomer({ customerFaq }) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [mobIndex, setMobIndex] = React.useState(null);

  if (customerFaq)
    return (
      <>
        <div className="faq-container">
          <h1>Frequently Asked Questions</h1>

          <div className="qa-container">
            <div className="question-container">
              {customerFaq.map((faq, index) => (
                <button
                  onClick={() => setSelectedIndex(index)}
                  style={{
                    backgroundColor:
                      selectedIndex === index ? "#493e80" : "#000",
                  }}
                  className="question"
                >
                  <div
                    className={selectedIndex === index ? "selected" : "default"}
                  />
                  <p>{faq.fields.Question}</p>

                  <div className="icon">
                    {selectedIndex === index ? (
                      <SelectedArrow />
                    ) : (
                      <DefaultArrow />
                    )}
                  </div>
                </button>
              ))}
            </div>

            <div className="faq-qa-container">
              <h4>{customerFaq[selectedIndex].fields.Question}</h4>
              <p>{customerFaq[selectedIndex].fields.Answer}</p>
            </div>
          </div>
        </div>

        <Flex
          flexDir={"column"}
          display={{ md: "none" }}
          py={4}
          w={"100vw"}
          px={6}
          // className="faq-container-mob"
        >
          <Heading
            w={"90vw"}
            fontSize={"1.5rem"}
            fontFamily={"Lexend"}
            mb={10}
            className="gradient-text"
          >
            Frequently Asked Questions
          </Heading>

          {customerFaq.map((faq, index) => (
            <Flex
              minH={"40px"}
              // px={2}
              alignSelf={"center"}
              display={"flex"}
              flexDir={"column"}
              bg={"transparent"}
              mb={8}
              _hover={{ bg: "transparent", scale: 1 }}
              onClick={() =>
                setMobIndex((prev) => (prev === index ? null : index))
              }
              className="question"
            >
              <Flex justifyContent={"space-between"} w={"90vw"}>
                <Heading
                  color={"#fff"}
                  size={"sm"}
                  fontFamily={"Poppins"}
                  w={"80%"}
                >
                  {faq.fields.Question}
                </Heading>

                <div
                  className="icon"
                  style={{
                    width: "20%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  {mobIndex === index ? <Minus /> : <Plus />}
                </div>
              </Flex>

              {mobIndex === index ? (
                <Text
                  mt={2}
                  fontFamily={"Lexend"}
                  fontWeight={"light"}
                  color={"#fff"}
                  opacity={0.7}
                >
                  {faq.fields.Answer}
                </Text>
              ) : null}
            </Flex>
          ))}
        </Flex>
      </>
    );

  return null;
}

export default FaqCustomer;
