import React from "react";
import "./Header.scss";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";

function Header({
  hideControls,
  type,
}: {
  hideControls: boolean;
  type: string | null;
}) {
  // useLocation
  const location = useLocation();

  // useNavigate
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/");
  };

  // height: 100px;
  //   width: 100vw;
  //   background-color: #000;

  return (
    <Box
      as="header"
      bg={"black"}
      display={{ base: "none", md: "flex" }}
      w={"100vw"}
      h={"10vh"}
      px={"5vw"}
      pos={"fixed"}
      zIndex={1000}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <button
        onClick={onClick}
        style={{
          alignItems: "center",
          backgroundColor: "transparent",
          borderWidth: 0,
        }}
      >
        <Image src={"svgs/logo.svg"} h={"7vh"} alt="zap_logo" />
      </button>

      {!hideControls && (
        <>
          {type === "Customer" ? (
            <Flex
              as={"nav"}
              w={"90vw"}
              justifyContent={"center"}
              gap={8}
              pos={"absolute"}
              fontWeight={"600"}
              fontSize={"18px"}
              alignSelf={"center"}
              alignItems={"center"}
            >
              {[
                { value: "Home", href: "/forCustomer" },
                { value: "Why Zap", href: "#why-zap" },
                { value: "Demo", href: "#zap-in-action" },
                { value: "Register", href: "#customer-form" },
              ].map((item) => (
                <Text
                  _hover={{ cursor: "pointer" }}
                  as={"a"}
                  color={"#fff"}
                  fontFamily={"Lexend"}
                  href={item.href}
                >
                  {item.value}
                </Text>
              ))}
            </Flex>
          ) : null}

          <Flex
            // alignSelf={"flex-end"}
            w={"360px"}
            h={"6vh"}
            pos={"relative"}
            borderRadius={"20px"}
            backgroundColor={"#373737"}
          >
            <motion.div
              style={{
                background: `linear-gradient(147deg, #238148 0%, #4ee089 100%)`,
                width: "50%",
                borderRadius: 20,
                height: "100%",
                zIndex: 1,
                position: "absolute",
                left: 0,
              }}
              initial={{ x: 0 }}
              animate={{ x: location.pathname === "/forSaaS" ? 360 / 2 : 0 }}
              transition={{ type: "spring", duration: 0.4 }}
            />

            <NavLink
              style={() => ({ textDecoration: "none" })}
              className={({ isActive }) =>
                isActive || location.pathname === "/customer"
                  ? "item selected-item"
                  : "item"
              }
              to={"/forCustomer"}
            >
              <Text
                fontFamily={"Poppins"}
                fontWeight={"700"}
                fontSize={"18px"}
                letterSpacing={"-1.2px"}
              >
                for Customers
              </Text>
            </NavLink>

            <NavLink
              className={({ isActive }) =>
                isActive ? "item selected-item" : "item"
              }
              style={() => ({ textDecoration: "none" })}
              to={"/forSaaS"}
            >
              <Text
                fontFamily={"Poppins"}
                fontWeight={"700"}
                fontSize={"18px"}
                letterSpacing={"-1.2px"}
              >
                for SaaS
              </Text>
            </NavLink>
          </Flex>
        </>
      )}
    </Box>
  );
}

export default Header;
