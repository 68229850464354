import React from "react";
import "./styles.scss";
import { chain, shuffle } from "lodash";
import { Box, Flex, GridItem, Image, SimpleGrid } from "@chakra-ui/react";
import { motion } from "framer-motion";

function ParticipantCompanies() {
  // useMemo
  const participants = React.useMemo(() => {
    const tempArr = ["dive", "factors", "gallabox", "rocketlane", "zocket"];

    return shuffle(tempArr);
  }, []);

  const participants_mob = React.useMemo(() => {
    const tempArr = ["dive", "factors", "gallabox", "rocketlane", "zocket"];

    return chain(tempArr).shuffle().chunk(4).value();
  }, []);

  return (
    <>
      <Box
        justifyContent={"center"}
        alignItems={"center"}
        as="section"
        display={{ base: "none", md: "flex" }}
        pt={0}
        pb={32}
        w={"90vw"}
        mx={"5vw"}
        alignSelf={"center"}
      >
        <motion.div
          initial={{
            background: `linear-gradient(90deg,#ffffff00 0%,#ffffff00 55.73%,#ffffff00 95.31%)`,
          }}
          viewport={{ once: true }}
          whileInView={{
            background: `linear-gradient(90deg,#d735ff 0%,#8729ff 55.73%,#915fff 95.31%)`,
          }}
          transition={{ duration: 0.5, delay: 0.75, type: "spring" }}
          style={{
            paddingLeft: "40px",
            paddingRight: "40px",
            paddingTop: "100px",
            paddingBottom: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            borderRadius: "20px",
            gap: 20,
            width: "90vw",
          }}
        >
          <motion.h1
            initial={{ fontSize: "6rem", opacity: 1, y: 100 }}
            viewport={{ once: true }}
            whileInView={{ fontSize: "3.75rem", opacity: 0, y: 0 }}
            style={{
              fontWeight: "700",
              letterSpacing: "-1px",
              fontSize: "6rem",
              zIndex: 100,
              fontFamily: "Lexend",
            }}
            transition={{ duration: 0.5, delay: 0.75, type: "spring" }}
            className="gradient-text"
          >
            Participating companies
          </motion.h1>

          <motion.h1
            initial={{ fontSize: "6rem", opacity: 0, y: 100 }}
            viewport={{ once: true }}
            whileInView={{ fontSize: "3.75rem", opacity: 1, y: 0 }}
            style={{
              fontWeight: "700",
              letterSpacing: "-1px",
              fontSize: "6rem",
              position: "absolute",
              alignSelf: "center",
              top: 100,
              color: "#fff",
              fontFamily: "Lexend",
            }}
            transition={{ duration: 0.5, delay: 0.75, type: "spring" }}
          >
            Participating companies
          </motion.h1>

          <motion.div
            initial={{ opacity: 0 }}
            viewport={{ once: true }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.75, type: "spring" }}
            style={{ alignSelf: "center" }}
          >
            <SimpleGrid
              columns={{
                md: participants.length > 7 ? 7 : participants.length,
                base: 8,
              }}
              gap={2}
              alignSelf={"center"}
              className="participants"
            >
              {participants.map((participant, index) => (
                <Box as={GridItem} colSpan={{ md: 1, base: 2 }}>
                  <motion.img
                    initial={{ x: 100, opacity: 0 }}
                    viewport={{ once: true }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{
                      duration: (index + 1) * 0.25,
                      delay: 0.75,
                      type: "spring",
                    }}
                    src={`svgs/participants/${participant}.svg`}
                  />
                </Box>
              ))}
            </SimpleGrid>
          </motion.div>
        </motion.div>
      </Box>

      <motion.div
        initial={{
          background: `linear-gradient(90deg,#000 0%,#000 55.73%,#000 95.31%)`,
        }}
        viewport={{ once: true }}
        whileInView={{
          background: `linear-gradient(90deg,#d735ff 0%,#8729ff 55.73%,#915fff 95.31%)`,
        }}
        transition={{ duration: 0.5, delay: 0.75, type: "spring" }}
        style={{ width: "100vw" }}
      >
        <Flex
          flexDir={"column"}
          alignItems={"center"}
          py="40px"
          px="20px"
          w={"100vw"}
          pos={"relative"}
          display={{ md: "none" }}
          justifyContent={"center"}
        >
          <motion.h1
            initial={{ fontSize: "1.75rem", y: 100 }}
            whileInView={{ fontSize: "1.5rem", y: 0 }}
            viewport={{ once: true }}
            style={{
              zIndex: 100,
              textAlign: "center",
              fontFamily: "Lexend",
              fontWeight: "700",
            }}
            transition={{ duration: 0.5, delay: 0.5, type: "spring" }}
          >
            Participating companies
          </motion.h1>

          <Flex
            flexDir={"column"}
            alignItems={"center"}
            gap={4}
            alignSelf={"center"}
            mt={10}
          >
            {participants_mob.map((item) => {
              return (
                <Flex alignSelf={"center"} alignItems={"center"} gap={4}>
                  {item.map((participant, index) => {
                    return (
                      <motion.div
                        initial={{ y: 100, opacity: 0 }}
                        viewport={{ once: true }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{
                          duration: (index + 1) * 0.25,
                          delay: 0.75,
                          type: "spring",
                        }}
                      >
                        <Image
                          h={"20vw"}
                          w={"20vw"}
                          src={`svgs/participants/${participant}.svg`}
                        />
                      </motion.div>
                    );
                  })}
                </Flex>
              );
            })}
            {/* <SimpleGrid
              columns={{ md: 7, base: 8 }}
              gap={2}
              alignItems={"center"}
              display={"flex"}
              alignSelf={"center"}
              className="participants"
            >
              {participants.map((participant) => (
                <Box as={GridItem} colSpan={{ md: 1, base: 2 }}>
                  <img src={`svgs/participants/${participant}.svg`} />
                </Box>
              ))}
            </SimpleGrid> */}
          </Flex>
        </Flex>
      </motion.div>
    </>
  );
}

export default React.memo(ParticipantCompanies);
// export default MotionWrap(
//   React.memo(ParticipantCompanies),
//   "app__participants"
// );
