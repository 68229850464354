import React from "react";
import "./styles.scss";
import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import MotionWrap from "../../../utils/MotionWrap";

function Hero() {
  // functions
  const getStarted = () => {
    const hash = "customer-form";
    const element = document.getElementById(hash);
    if (element) element.scrollIntoView({ behavior: "smooth" });
    return;
  };

  const showMeHow = () => {
    const hash = "zap-in-action";
    const element = document.getElementById(hash);
    if (element) element.scrollIntoView({ behavior: "smooth" });
    return;
  };

  return (
    <Flex
      flexDir={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      as="section"
      bg={"black"}
      bgImage={`url(${process.env.PUBLIC_URL + "/svgs/hero-bg.svg"})`}
      bgSize={"cover"}
      px={{ base: 6 }}
      h={{ md: `100vh`, base: "100vh" }}
      pt={{ base: "82px", md: "90px" }}
    >
      <motion.div
        className="bnpl-container"
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
      >
        <Flex px={"36px"} py={"12px"} bg={"#000"} borderRadius={"24px"}>
          <Text
            className="gradient-text"
            fontFamily={"Lexend"}
            fontSize={{ md: "36px", base: "20px" }}
            display={{ md: "block", base: "none" }}
          >
            Buy Now Pay Later for SaaS is here
          </Text>
          <Text
            className="gradient-text"
            fontFamily={"Lexend"}
            fontSize={{ md: "36px", base: "20px" }}
            display={{ md: "none", base: "block" }}
            textAlign={"center"}
          >
            Buy Now Pay Later
            <br />
            for SaaS is here
          </Text>
        </Flex>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
      >
        <Heading
          fontSize={{ base: "2.5rem", md: "160px" }}
          lineHeight={{ base: "2.5rem", md: "102%" }}
          fontFamily={"GothamUltra"}
          textAlign={"center"}
          fontWeight={"900"}
          color={"#fff"}
        >
          Buy{" "}
          <Text
            as={"span"}
            textDecor={"line-through"}
            textDecorationThickness={{ md: "36px", base: "10px" }}
            textDecorationColor={"rgba(255, 128, 8, 0.89)"}
          >
            annual
          </Text>
          <br />
          Pay <span className="gradient-text">monthly</span>
        </Heading>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
      >
        {/* <Text
          textAlign={"center"}
          mt={{ base: 4, md: 8 }}
          fontFamily={"Lexend"}
          fontSize={{ base: 14, md: 24 }}
          className="gradient-text-secondary"
        > */}
        <Heading
          // fontFamily={"Lexend"}
          // size={{ base: "sm", md: "lg" }}
          // color={"#fff"}
          // className="gradient-text-secondary"
          fontWeight={"400"}
          textAlign={"center"}
          // mt={{ base: 4, md: 8 }}
          fontFamily={"Lexend"}
          fontSize={{ base: 14, md: 24 }}
          className="gradient-text-secondary"
          mt={{ base: 4, md: 4 }}
        >
          ✨ What if you can get an annual discount while paying monthly? ✨
        </Heading>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
      >
        <Flex mb={6} mt={12} gap={{ base: 3, md: 6 }}>
          <Button
            onClick={getStarted}
            w={{ base: 36, md: 40 }}
            _hover={{
              bg: "linear-gradient(147deg, rgba(35, 129, 72, 0.00) 0%, #2B813F 6.77%)",
            }}
            rounded={"full"}
            color={"#fff"}
            fontSize={{ base: "xs", md: "md" }}
            size={{ base: "md", md: "lg" }}
            bg={
              "linear-gradient(147deg, rgba(35, 129, 72, 0.00) 0%, #2B813F 6.77%)"
            }
            // fontFamily={"Poppins"}
          >
            Get Started
          </Button>
          <Button
            onClick={showMeHow}
            bg={"#3E3E3E"}
            w={{ base: 36, md: 40 }}
            _hover={{ bg: "#3E3E3E" }}
            rounded={"full"}
            fontSize={{ base: "xs", md: "md" }}
            size={{ base: "md", md: "lg" }}
            color={"#fff"}
            // fontFamily={"Poppins"}
          >
            Show me how?
          </Button>
        </Flex>
      </motion.div>
    </Flex>
  );
}

export default MotionWrap(Hero, "app__skills");
