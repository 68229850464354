import React from "react";
import "./App.css";
import Header from "./Components/Header";
import Customer from "./Pages/Customer";
import Partner from "./Pages/Partner";
import axios from "axios";
import _ from "lodash";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { Box, ChakraProvider, Flex, Text } from "@chakra-ui/react";
import Terms from "./Pages/Terms";
import RefundCancellation from "./Pages/RefundCancellation";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

function Promo() {
  React.useEffect(() => {
    window.location.replace("https://www.paywithzap.com/forSaaS");
  }, []);

  return null;
}

function App() {
  // useState
  const [customerFaq, setCustomerFaq] = React.useState<any[] | null>(null);
  const [partnerFaq, setPartnerFaq] = React.useState<any[] | null>(null);

  const { pathname } = useLocation();

  // useEffect
  React.useEffect(() => {
    axios
      .get(
        `https://api.airtable.com/v0/appZSlakpHlppBmND/FAQs?sort%5B0%5D%5Bfield%5D=rank&sort%5B0%5D%5Bdirection%5D=asc`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_TOKEN}`,
          },
        }
      )
      .then((e) => {
        setPartnerFaq(
          _.filter(e.data.records, (data) => data.fields.type === "Partner")
        );
        setCustomerFaq(
          _.filter(e.data.records, (data) => data.fields.type === "Customer")
        );
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <ChakraProvider>
      {(pathname.includes("forSaaS") ||
        pathname.includes("customer") ||
        pathname.includes("forCustomer") ||
        pathname.includes("privacy_policy") ||
        pathname.includes("terms_and_conditions") ||
        pathname.includes("refund_and_cancellation")) && (
        <Header
          hideControls={
            pathname.includes("privacy_policy") ||
            pathname.includes("refund_and_cancellation") ||
            pathname.includes("terms_and_conditions")
          }
          type={
            pathname.includes("forSaaS")
              ? "SaaS"
              : pathname.includes("forSaaS") || pathname.includes("customer")
              ? "Customer"
              : null
          }
        />
      )}

      <Routes>
        <Route path="/forSaaS" element={<Partner partnerFaq={partnerFaq} />} />
        <Route
          path="/forCustomer"
          element={<Customer customerFaq={customerFaq} />}
        />
        <Route
          path="/customer"
          element={<Customer customerFaq={customerFaq} />}
        />
        <Route path="/promo_qr" element={<Promo />} />
        <Route path="privacy_policy" element={<PrivacyPolicy />} />
        <Route path="terms_and_conditions" element={<Terms />} />
        <Route
          path="refund_and_cancellation"
          element={<RefundCancellation />}
        />
        <Route path="/" element={<Navigate to="/forCustomer" />} />
      </Routes>

      <Flex
        as={"footer"}
        alignItems={"center"}
        bg={`linear-gradient(180deg, #01250F 0%, #000 100%)`}
        w={"100vw"}
        flexDir={"column"}
        p={8}
      >
        {/* <Button
          onClick={() => window.scrollTo(0, 0)}
          bg={"transparent"}
          _hover={{ bg: "transparent" }}>
          <Image src="/svgs/logo.svg" alt="logo" width={"120px"} />
        </Button> */}
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          mb={"10"}
          gap={10}
        >
          <Text
            as={"a"}
            href="/privacy_policy"
            color={"#fff"}
            fontFamily={"Lexend"}
            fontSize={"lg"}
          >
            Privacy Policy
          </Text>
          <Text
            as={"a"}
            href="/terms_and_conditions"
            color={"#fff"}
            fontFamily={"Lexend"}
            fontSize={"lg"}
          >
            Terms & Conditions
          </Text>
          <Text
            as={"a"}
            href="/refund_and_cancellation"
            color={"#fff"}
            fontFamily={"Lexend"}
            fontSize={"lg"}
          >
            Refund & Cancellation
          </Text>
        </Flex>

        <Flex
          mt={4}
          alignItems="flex-start"
          w={"full"}
          gap={20}
          flexDir={{ base: "column", md: "row" }}
          justifyContent={"space-between"}
        >
          <Box>
            <Text fontSize={"lg"} fontFamily={"Lexend"} color={"#fff"}>
              <Text as={"span"} fontWeight={"bold"} className="gradient-text">
                Zap HQ
              </Text>{" "}
              Velozity, Inc
            </Text>
            <Text fontFamily={"Lexend"} color={"#fff"} mt={2} opacity={0.7}>
              🇺🇸 5214F Diamond Heights Blvd
              <br />
              #3223 San Francisco, CA, 94131
              <br />
              United States
            </Text>
          </Box>

          <Box>
            <Text
              fontSize={"lg"}
              as={"span"}
              fontFamily={"Lexend"}
              fontWeight={"bold"}
              className="gradient-text"
            >
              Product & Engineering
            </Text>{" "}
            <Text fontFamily={"Lexend"} color={"#fff"} mt={2} opacity={0.7}>
              🇮🇳 HSR Layout, Bangalore
            </Text>
          </Box>
        </Flex>
      </Flex>
    </ChakraProvider>
  );
}

export default App;
